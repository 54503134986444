import React, {useEffect, useState, Fragment} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import DatePicker from "react-datepicker";

import GraphTemp from './GraphTemp';
import GraphHumi from './GraphHumi';
import GraphVolt from './GraphVolt';
import GraphCur from './GraphCur';
import GraphFuel from './GraphFuel';
import GraphTotal from './GraphTotal';

import urlSite from './ConfigUrl';

import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';

// const URL_API_GRAPHES = 'https://siscos2.ogur.net:5001/api/getdataforgraphes';
const URL_API_GRAPHES = urlSite.URL_API_GRAPHES;

const Dashboard = (props) => {
  const navigate = useNavigate();

  const [startDate, setStartDate] = useState(new Date());

  const [stateDataGrapheLabels, setStateDataGrapheLabels] = useState([]);

  const [stateDataGraphe, setStateDataGraphe] = useState([]);
  const [stateDataGrapheTemp, setStateDataGrapheTemp] = useState([]);
  const [stateDataGrapheHumi, setStateDataGrapheHumi] = useState([]);
  const [stateDataGrapheVolt, setStateDataGrapheVolt] = useState([]);
  const [stateDataGrapheCur, setStateDataGrapheCur] = useState([]);
  const [stateDataGrapheFuel, setStateDataGrapheFuel] = useState([]);
  const [stateDataGrapheTotal, setStateDataGrapheTotal] = useState([]);

  const [stateWithPicker, setStateWithPicker] = useState(false);

    if ( !localStorage.getItem('siscos30') ) {
        setTimeout(function() {
            navigate('/');
        }, 100);
    }
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
	const sendReqToBackForGrapheData = async (startDate) => {
    setStartDate(startDate);

    let yearForDate = startDate.toLocaleString("default", { year: "numeric" });
    let monthForDate = startDate.toLocaleString("default", { month: "2-digit" });
    let dayForDate = startDate.toLocaleString("default", { day: "2-digit" });
    const dateToSend = yearForDate + monthForDate + dayForDate;

    const cors = {mode:'cors'};
    const params = { D_DATE: dateToSend};
    await axios.post(URL_API_GRAPHES, params, cors)
        .then(response => {

            if (response.data.msg === 'SUCCESS_GRAPH') {

              let values = response.data.result;
              setStateDataGraphe(values);

              // time for all data
              let valuesLabels = response.data.result.map((item) => item.D_TIME);
              setStateDataGrapheLabels(valuesLabels);

              // temp
              let valuesTemp = response.data.result.map((item) => item.D_TEMP);
              setStateDataGrapheTemp(valuesTemp);

              // humi
              let valuesHumi = response.data.result.map((item) => item.D_HUMI);
              setStateDataGrapheHumi(valuesHumi);

              // volt
              let valuesVolt = response.data.result.map((item) => item.D_VOLTAGE);
              setStateDataGrapheVolt(valuesVolt);


              // current
              let valuesCur = response.data.result.map((item) => item.D_CURRENT);
              setStateDataGrapheCur(valuesCur);
              

              // fuel
              let valuesFuel = response.data.result.map((item) => item.D_FUEL);
              setStateDataGrapheFuel(valuesFuel);

              // total consumption
              let valuesTotal = response.data.result.map((item) => item.D_TOTAL);
              setStateDataGrapheTotal(valuesTotal);

                notify('Got data from graph API.', 'goood');
            }
            
            else if (response.data.msg === 'NO_DATA') {
                notify('There is no data for this date.', 'baaad');
            }
        })
        .catch(error => {
          notify('There is no data for this date.', 'baaad');
        });
    }
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=--=-=-=-=-=-=-=-=-=-=
   useEffect(() => {
       toast.promise(sendReqToBackForGrapheData(startDate), {
                               loading: "Getting graph data from database...",
                               error: "There was an error while fetching the data",
                               success: "Successfully got data."
                                 });
                   }, []);
   // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
    useEffect(() => {
      const interval = setInterval(() => {
        sendReqToBackForGrapheData(startDate);
      }, 30000);
      return () => clearInterval(interval);
    }, []);
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
      const notify = (thigstonotify, goodorbad) => {
        if (goodorbad === 'goood') {
            toast.success(thigstonotify);
        }
        else if (goodorbad === 'baaad') {
            toast.error(thigstonotify);
        }
      };
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= 
  return (
    <Fragment>
      <div className = "row mb-2">
        <span className = "ms-1 bg-light text-dark badge fw-normal fs-5">
            <div>
              <small className = "float-start text-muted">
                  <span
                    className = "badge rounded-pill my-backgroud-section fs-6 m-1 fw-normal">
                       All graphes
                   </span>
              </small>

              {/* <div style = {{width: 350}} className = "text-center text-muted">
                <DatePicker
                  className = "form-control widh_picker"
                  portalId = "root-portal"
                  selected = {startDate}
                  onChange = {(date) => sendReqToBackForGrapheData(date)}
                />
              </div> */}
                                  
              <small className = "float-end text-muted">
                <input type = "search" className = "form-control" placeholder = "Filter sites..."/>
              </small>
                                    
            </div>
        </span>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <GraphTemp
              p_datatemp = {stateDataGrapheTemp}
              p_withpicker = {stateWithPicker}
              p_tempLabels = {stateDataGrapheLabels}
            />
          </div>
          <div className="col-md-6">
            <GraphHumi
              p_datahumi = {stateDataGrapheHumi}
              p_withpicker = {stateWithPicker}
              p_tempLabels = {stateDataGrapheLabels}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <GraphVolt
              p_datavolt = {stateDataGrapheVolt}
              p_withpicker = {stateWithPicker}
              p_tempLabels = {stateDataGrapheLabels}
            />
          </div>
          <div className="col-md-6">
            <GraphCur
              p_datacur = {stateDataGrapheCur}
              p_withpicker = {stateWithPicker}
              p_tempLabels = {stateDataGrapheLabels}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <GraphFuel
              p_datafuel = {stateDataGrapheFuel}
              p_withpicker = {stateWithPicker}
              p_tempLabels = {stateDataGrapheLabels}
            />
          </div>
          <div className="col-md-6">
            <GraphTotal
              p_datatotal = {stateDataGrapheTotal}
              p_withpicker = {stateWithPicker}
              p_tempLabels = {stateDataGrapheLabels}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}
export default Dashboard;
// =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=