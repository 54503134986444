import React, { useState, useEffect, Fragment } from 'react';
import { Route, Routes } from 'react-router-dom';


import SignUp from './components/SignUp';
import Login from './components/Login';
import Settings from './components/Settings';
import SettingsUser from './components/SettingsUser';
import Archives from './components/Archives';
import Sites from './components/Sites';
import Map from './components/Map';
import ShowSite from './components/ShowSite';
import Header from './components/Header';
import Dashboard from './components/Dashboard';

import {decodeTheCurrUserToken} from './getcurrentuser';

import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';

const App = () => {
	const [currentUserState, setCurrentUserState] = useState({id: '', llogin: '', rrole: ''});
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  useEffect(() => {
					try{
						// try it, if it returns undefined or null, do nothing
						const decodedJwt = decodeTheCurrUserToken();
						setCurrentUserState((prev) => ({
							id: decodedJwt.id,
							llogin: decodedJwt.llogin,
							rrole: decodedJwt.rrole
						}));
					}
					catch (except) {}
				}, []);
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  	return (
  		<Fragment>
	  		<Header p_currUser = {currentUserState} />
			<Routes>
		  		<Route path = "/signup" element = {<SignUp />} />
		  		<Route path = "/archives" element = {<Archives />} />
		  		<Route path = "/settings" element = {<Settings />} />
		  		<Route path = "/users" element = {<SettingsUser p_currUser = {currentUserState}/>} />
		  		<Route path = "/sites" element = {<Sites />} />
		  		<Route path = "/map" element = {<Map />} />
		  		<Route path = "/dashboard" element = {<Dashboard />} />
		  		<Route path = "/showsite/:siteid" element = {<ShowSite />} />
				<Route path = "/" element = {<Login />} />
	  		</Routes>
		</Fragment>
			);}
 // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
 export default App;
