import React, { Fragment } from 'react';
import ArchivesAlerts from './ArchivesAlerts';
import ArchivesUserActions from './ArchivesUserActions';
import ArchivesUserActions2 from './ArchivesUserActions2';
import { useNavigate } from 'react-router-dom';

const Archives = () => {
	const navigate = useNavigate();

	if ( !localStorage.getItem('siscos30') ) {
		setTimeout(function() {
		  navigate('/');
		}, 100);
	} 	
	// =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
	return (
		<Fragment>
			<ArchivesAlerts />
			<ArchivesUserActions />
			<ArchivesUserActions2 />
		</Fragment>
	);
  }
 // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
 export default Archives;
