import React, {useEffect, useState, Fragment} from 'react';
import DatePicker from "react-datepicker";
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
//import socketIO from 'socket.io-client';

import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';

import urlSite from './ConfigUrl';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Site Andraharo',
    },
  },
};

const URL_API_GRAPHES_TM = urlSite.URL_API_GRAPHES_TM;

const GraphTemp = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [stateDataGrapheTemp, setStateDataGrapheTemp] = useState(props.p_datatemp);

  // we need to handle the time axis localy like the data when the user changes the date
  const [stateDataGrapheLabels, setStateDataGrapheLabels] = useState(props.p_tempLabels);

  //const socket = socketIO.connect('https://siscos2.ogur.net:5001');

  // socket.on('WE_HAVE_NEW_DATA', () => {
  //   console.log('test tonga ve ny data.');
  // });
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
	const sendReqToBackForGrapheDataTemp = async (dateazo) => {
    setStartDate(dateazo);

    let yearForDate = dateazo.toLocaleString("default", { year: "numeric" });
    let monthForDate = dateazo.toLocaleString("default", { month: "2-digit" });
    let dayForDate = dateazo.toLocaleString("default", { day: "2-digit" });

    const dateToSend = yearForDate + monthForDate + dayForDate;

    const cors = {mode:'cors'};
    const params = { D_DATE: dateToSend};

    await axios.post(URL_API_GRAPHES_TM, params, cors)
        .then(response => {

          // time for all data
          let valuesLabels = response.data.result.map((item) => item.D_TIME);
          setStateDataGrapheLabels(valuesLabels);

          let valuesTemp = response.data.result.map((item) => item.D_TEMP);
          setStateDataGrapheTemp(valuesTemp);
          console.log('DATA: ', valuesTemp);
          
          if (response.data.msg === 'SUCCESS_GRAPH') {
            console.log('got_data_for_graph');
        }
        else if (response.data.msg === 'NO_DATA') {
            notify('There is no temperature data for this date.', 'baaad');
        }
        })
        .catch(error => {
          notify('There is no data for this date.', 'baaad');
        });
    }
    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
    useEffect(() => {
      // set the data of temp from props
      setStateDataGrapheTemp(props.p_datatemp);

      // set the time of temp from props
      setStateDataGrapheLabels(props.p_tempLabels);
    },[props.p_datatemp]);
    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
    const notify = (thigstonotify, goodorbad) => {
      if (goodorbad === 'goood') {
          toast.success(thigstonotify);
      }
      else if (goodorbad === 'baaad') {
          toast.error(thigstonotify);
      }
    };
// =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=

  return (
    <Fragment>

      <Toaster position = "top-center" reverseOrder = {false} />                           
                              {/*-----------------------------------------*/}
                              <div className = "text-center rounded">
                                  
                                  {props.p_withpicker ? <div className = "text-center text-muted">
                                    <DatePicker
                                      className = "form-control text-center widh_picker"
                                      portalId = "root-portal"
                                      selected = {startDate}
                                      onChange = {(date) => sendReqToBackForGrapheDataTemp(date)}
                                    />
                                  </div> : null}
                              {/*-----------------------------------------*/}
                                  <span className = 'card border-info m-1' style={{width: 640, display: 'inline-block'}}>
                                    <div className = "align-items-center">

                                      <span className = "row align-items-center">
                                              <Line
                                                    options = {options}
                                                    data = {{
                                                        labels: stateDataGrapheLabels,
                                                        datasets: [
                                                          {
                                                            label: 'Temperature °C',
                                                            // ireto soloina valeur av any anaty base avy eo refa tonga ny data av an am back ny site
                                                            data: stateDataGrapheTemp,
                                                            borderColor: 'rgb(53, 162, 235)',
                                                            backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                                          },
                                                        ],
                                                      }
                                                    }
                                              />
                                      </span>
                                    </div>

                                  </span>
                              </div>
                              {/*-----------------------------------------*/}
    </Fragment>
  );
}
export default GraphTemp;
// =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=