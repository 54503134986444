import React, { useState, Fragment } from 'react';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from "react-router-dom";

import LogoOgur from '../assets/logo_ogur2.png';

import '../forms.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';

import urlSite from './ConfigUrl';

// const URL_API_SIGNUP = 'http://node23942-ogursec-back.rag-cloud.hosteur.com:11036/api/register';
// const URL_API_SIGNUP = 'https://siscos2.ogur.net:5001/api/register';
const URL_API_SIGNUP = urlSite.URL_API_SIGNUP;


const SignUp = (props) => {
    const navigate=useNavigate();
    const [dataForm, setStateForm] = useState({
        nameField: '',
        emailField: '',
        passwordField1: '',
        passwordField2: '',
        numberField: ''
    });
    const [error, setError] = useState({
        passwordField1: '',
        passwordField2: ''
      });
    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
    const handleFields = event => {
        const { name, value } = event.target;
        setStateForm((prev) => {
            prev[name] = value;
            return prev;
        });
        validateInput(event);
    };
    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
    const validateInput = event => {
        event.preventDefault()

        let { name, value } = event.target;
        
        setError(prev => {
          const stateObj = { ...prev, [name]: "" };
          console.log(typeof(value));
          switch (name) {
            case "passwordField1":
              if (value === "") {
                stateObj[name] = "Please enter your password";
              }
              else if (dataForm.passwordField2 && value !== dataForm.passwordField2) {
                stateObj["passwordField2"] = "Passwords do not match";
              }
              else {
                stateObj["passwordField2"] = dataForm.passwordField2 ? "" : error.passwordField2;
              }
              break;
     
            case "passwordField2":
              if (value === "") {
                stateObj[name] = "Please confirm your password";
              }
              else if (dataForm.passwordField1 && value !== dataForm.passwordField1) {
                stateObj[name] = "Passwords do not match";
              }
              break;
     
            default:
              break;
          }
          return stateObj;
        });
      };
    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
    const sendReqToBack = () => {
        const cors = {mode:'cors'};
        const params = { 
                        name: dataForm.nameField,
                        email: dataForm.emailField,
                        password: dataForm.passwordField1,
                        number: dataForm.numberField
                    };
        axios.post(URL_API_SIGNUP, params, cors)
            .then(response => {
                if (response.data.msg === 'SIGNUPSUCCESS') {
                    notify('You are now registered, please login to use the system.', 'goood');
                    navigate('/');
                }
                else if (response.data.msg === 'USEREXISTS') {
                    notify('A user with this email already exists, chose another one.', 'baaad');
                    navigate('/signup');
                }
            })
            .catch(error => {
                console.log(error);
                navigate('/');
            });
        };
    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
    const notify = (thigstonotify, goodorbad) => {
        if (goodorbad === 'goood') {
            toast.success(thigstonotify);
         }
         else if (goodorbad === 'baaad') {
            toast.error(thigstonotify);
         }
    };
    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
    return (
      <Fragment>

        <Toaster position = "top-center" reverseOrder = {false} />

        <div className = "login-page bg-light">
            <div className = "container">
                <div className = "row">
                    <div className = "col-lg-10 offset-lg-1">
                        <div className = "bg-white shadow rounded">
                            <div className = "row">
                                <div className = "col-md-7 pe-0">
                                    <div className = "form-left h-100 py-5 px-5">
                                        <form className = "row g-4">
                                                {/* -------------------------------------------------------------- */}
                                                <div className = "col-12">
                                                    <label>Name<span className = "text-danger">*</span></label>
                                                    <div className = "input-group">
                                                        <div className = "input-group-text"><i className = "fa fa-user"></i></div>
                                                        <input
                                                            type="text"
                                                            name = "nameField"
                                                            className = "form-control"
                                                            value = {dataForm.nameField}
                                                            onChange = { (event) => handleFields(event)}
                                                        />
                                                    </div>
                                                </div>
                                                {/* -------------------------------------------------------------- */}
                                                <div className = "col-12">
                                                    <label>Email<span className = "text-danger">*</span></label>
                                                    <div className = "input-group">
                                                        <div className = "input-group-text"><i className = "fa fa-envelope"></i></div>
                                                        <input
                                                            type="text"
                                                            name = "emailField"
                                                            className = "form-control"
                                                            value = {dataForm.emailField}
                                                            onChange = { (event) => handleFields(event)}
                                                        />
                                                        
                                                    </div>
                                                </div>
                                                {/* -------------------------------------------------------------- */}
                                                <div className = "col-12">
                                                    <label>Password<span className = "text-danger">*</span></label>
                                                    <div className = "input-group">
                                                        <div className = "input-group-text"><i className = "fa fa-lock"></i></div>
                                                        <input
                                                            type = "password"
                                                            name = "passwordField1"
                                                            className = "form-control"
                                                            onBlur = {validateInput}
                                                            value = {dataForm.passwordField1}
                                                            onChange = {handleFields}
                                                        />
                                                    </div>
                                                    {error.passwordField1 && <span className = "text-danger">{error.passwordField1}</span>}
                                                </div>
                                                {/* -------------------------------------------------------------- */}
                                                <div className = "col-12">
                                                    
                                                    <label>Confirm password<span className = "text-danger">*</span></label>
                                                    <div className = "input-group">
                                                        <div className = "input-group-text"><i className = "fa fa-lock"></i></div>
                                                        <input
                                                            type = "password"
                                                            name = "passwordField2"
                                                            className = "form-control"
                                                            onBlur = {validateInput}
                                                            value = {dataForm.passwordField2}
                                                            onChange = {handleFields}
                                                        />
                                                        
                                                    </div>
                                                    {error.passwordField2 && <span className = "text-danger">{error.passwordField2}</span>}
                                                    
                                                </div>
                                                {/* -------------------------------------------------------------- */}
                                                <div className = "col-12">
                                                    <label>Phone number<span className = "text-danger">*</span></label>
                                                    <div className = "input-group">
                                                        <div className = "input-group-text"><i className = "fa fa-phone"></i></div>
                                                        <input
                                                            type="text"
                                                            name = "numberField"
                                                            id = "numberField"
                                                            className = "form-control"
                                                            placeholder="+261 303333333"
                                                            defaultValue = {dataForm.numberField}
                                                            onChange = { (event) => handleFields(event)}
                                                        />
                                                    </div>
                                                </div>
                                                {/* -------------------------------------------------------------- */}
                                                <div className = "col-12">
                                                    <input
                                                        type="button"
                                                        className = "btn btn-primary px-4 float-end mt-4"
                                                        value = 'Sign up'
                                                        onClick = { sendReqToBack }
                                                    />
                                                </div>
                                                {/* -------------------------------------------------------------- */}
                                        </form>
                                    </div>
                                </div>
                                <div className = "col-md-5 ps-0 d-none d-md-block">
                                    <div className = "form-right h-100 bg-primary text-white text-center pt-4">
                                        <img src = {LogoOgur} alt = "Logo Ogur" className = "align-self-center my-logo"></img>
                                        <h2 className = "fs-1">We are always ON !</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
      </Fragment>
      );
  }
 // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
 export default SignUp;
