import React, { Fragment , useState, useEffect } from 'react';
import jwtDecoder from 'jwt-decode';

import SettingsSites from './SettingsSites';
import { useNavigate } from 'react-router-dom';

const Settings = () => {

  const navigate = useNavigate();

  if ( !localStorage.getItem('siscos30') ) {
    setTimeout(function() {
      navigate('/');
    }, 100);
  } 

  const [currentUserState, setCurrentUserState] = useState({
      id: '',
      llogin: '',
      rrole: ''
    });
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  const decodeTheCurrUserToken = () => {
  const decodedJwt = jwtDecoder(localStorage.getItem('siscos30'));
  setCurrentUserState({
              id: decodedJwt.id,
              llogin: decodedJwt.llogin,
              rrole: decodedJwt.rrole
            });
  };
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  // run effect on every render, no dependency
  useEffect(() => {
          try{
            // try it, if it returns undefined or null, do nothing
            decodeTheCurrUserToken()
          }
          catch (except) {}
        }, []);
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  return (
      <Fragment>
          <SettingsSites p_currUser = {currentUserState} />
      </Fragment>
      );
  }
 // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
 export default Settings;
