import React, { useState, useEffect, Fragment } from 'react';
import { MDBDataTable } from 'mdbreact';

import '../index.css';

import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';

import axios from 'axios';
import urlSite from './ConfigUrl';

// const URL_ALL_Alerts = "http://node23942-ogursec-back.rag-cloud.hosteur.com:11036/api/getallalerts";

// const URL_ALL_ALERTS = "https://siscos2.ogur.net:5001/api/getallalerts";
const URL_ALL_ALERTS = urlSite.URL_ALL_ALERTS;

const ArchivesAlerts = () => {
  const [stateAllAlerts, setStateAllAlerts] = useState([]);
  const [stateDataAlerts, setStateDataAlerts] = useState({});
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  useEffect(() => {
    const getAlerts = async () => {
      await axios.get(URL_ALL_ALERTS).then((req_response) => {
        const data_alerts = req_response.data.data;
        setStateAllAlerts(data_alerts);
      });
    };
    getAlerts();
  }, []);
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  useEffect(() => {
    const buildTable = () => {
      const data_row_alerts = {
          // columns are fixed, they do not change in time
            columns: [
                      { label: 'Alerte date', field: 'ALERTDATE', sort: 'asc'},
                      { label: 'Alerte time', field: 'ALERTTIME', sort: 'asc'},
                      { label: 'Site', field: 'SITE', sort: 'asc'},
                      { label: 'Message', field: 'MESSAGE', sort: 'asc'},
                      { label: 'Alarm id', field: 'ALARM_ID', sort: 'asc'},
                      { label: 'SMS Status', field: 'SMS_STATUS', sort: 'asc'},
                      { label: 'SMS submit date', field: 'SMS_SUBMIT_DATE', sort: 'asc'}
                    ],
          // rows are dynamic, we handle them with state
            rows: stateAllAlerts
          };
        setStateDataAlerts(data_row_alerts);
    };
    buildTable();
  }, [stateAllAlerts]);
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
    return (
      <Fragment>
            <MDBDataTable
              className = "mt-5"
              striped
              info = {false}
              bordered
              entriesLabel = {<h5>Alerts: <span className = "badge bg-primary">{stateAllAlerts.length}</span></h5>}
              entriesOptions = {[10, 20, 30, 40, 50, 100, 500]}
              searchLabel = 'Filter alerts...'
              data = {stateDataAlerts}
            />
      </Fragment>
      );
  }
 // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
 export default ArchivesAlerts;
