import React, { useState, useEffect, Fragment } from 'react';
import { MDBDataTable } from 'mdbreact';

import '../index.css';

import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';

import axios from 'axios';
import urlSite from './ConfigUrl';

// const URL_ALL_UserActions = "http://node23942-ogursec-back.rag-cloud.hosteur.com:11036/api/getalluseractions";

// const URL_ALL_USERACTIONS = "https://siscos2.ogur.net:5001/api/getalluseractions";
const URL_ALL_USERACTIONS = urlSite.URL_ALL_USERACTIONS;

const ArchivesUserActions2 = () => {
  const [stateAllUserActions, setStateAllUserActions] = useState([]);
  const [stateDataUserActions, setStateDataUserActions] = useState({});
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  useEffect(() => {
    const getUserActions = async () => {
      await axios.get(URL_ALL_USERACTIONS).then((req_response) => {
        const data_useractions = req_response.data.data;
        setStateAllUserActions(data_useractions);
      });
    };
    getUserActions();
  }, []);
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  useEffect(() => {
    const buildTable = () => {
      const data_row_useractions = {
          // columns are fixed, they do not change in time
            columns: [
                      { label: 'Action date', name: 'DATE_TIME', options: { sort: true }},
                      { label: 'Reason', name: 'REASON', options: { sort: true }},
                      { label: 'Site', name: 'SITE', options: { sort: true }},
                      { label: 'Action', name: 'ACTION', options: { sort: true }},
                      { label: 'User', name: 'CONNECTED_USER', options: { sort: true }}
                    ],
          // rows are dynamic, we handle them with state
            rows: stateAllUserActions
          };
        setStateDataUserActions(data_row_useractions);
    };
    buildTable();
  }, [stateAllUserActions]);
  const options = {
    filterType: 'checkbox'
  };
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
    return (
      <Fragment>
            
      </Fragment>
      );
  }
 // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
 export default ArchivesUserActions2;
