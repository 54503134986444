import React, { useEffect, useState, Fragment } from 'react';
import { Modal, Button } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from "react-router-dom";

import {decodeTheCurrUserToken} from '../getcurrentuser';
import {backUpdateSite, backUpdateAtions, backUpdateInsertSite} from '../sendtobackend';
import '../index.css';

import urlSite from './ConfigUrl';

import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';

import axios from 'axios';

// const URL_ALL_SITES = "http://node23942-ogursec-back.rag-cloud.hosteur.com:11036/api/getallsites";
// const URL_ALL_SITES = "https://siscos2.ogur.net:5001/api/getallsites";
const URL_ALL_SITES = urlSite.URL_ALL_SITES;

const SettingsSites = (props) => {
    const navigate = useNavigate();
    const [stateAllSites, setStateAllSite] = useState([]);
    const [stateModal, setStateModal] = useState(false);
    const [stateNisySave, setStateNisySave] = useState();
    const [stateSelectedSite, setStateSelectedSite] = useState({
                                                SITE_DATA:
                                                    {
                                                      ID: '1',
                                                      NAME: '',
                                                      IP: '',
                                                      CURR_TEMP: '',
                                                      TEMP_TRES: '',
                                                      CURR_HUMI: '',
                                                      HUMI_TRES: '',

                                                      CURR_FUEL: '',
                                                      FUEL_TRES: '',
                                                      
                                                      CURR_VOLT: '',
                                                      VOLT_TRES: '',

                                                      CURR_TOTAL: '',
                                                      TOTAL_TRES: '',
                                                      
                                                      CURR_CUR: '',
                                                      CUR_TRES: '',
                                                      
                                                      GATEWAY: '',
                                                      STATUS: '',
                                                      MONITORED: '',
                                                      ALERT_SMS: '',
                                                      ALERT_EMAIL: '',
                                                      GPS_LAT: '',
                                                      GPS_LON: ''
                                                    },
                                                WIRED_DATA: [
                                                    {
                                                        num: '0',
                                                        namesys: '',
                                                        nameinputname: '',
                                                        nameuser: '',
                                                        currstatus: '',
                                                        license: ''
                                                    },
                                                    {
                                                        num: '1',
                                                        namesys: '',
                                                        nameinputname: '',
                                                        nameuser: '',
                                                        currstatus: '',
                                                        license: ''
                                                    },
                                                    {
                                                        num: '2',
                                                        namesys: '',
                                                        nameinputname: '',
                                                        nameuser: '',
                                                        currstatus: '',
                                                        license: ''
                                                    },
                                                    {
                                                        num: '3',
                                                        namesys: '',
                                                        nameinputname: '',
                                                        nameuser: '',
                                                        currstatus: '',
                                                        license: ''
                                                    }
                                                ],
                                                WIRELESS_DATA: [
                                                    {
                                                        num: '0',
                                                        namesys: '',
                                                        nameinputname: '',
                                                        nameinputfm: '',
                                                        nameuser: '',
                                                        currstatus: '',
                                                        license: '',
                                                        FM_CODE: ''
                                                    },
                                                    {
                                                        num: '1',
                                                        namesys: '',
                                                        nameinputname: '',
                                                        nameinputfm: '',
                                                        nameuser: '',
                                                        currstatus: '',
                                                        license: '',
                                                        FM_CODE: ''
                                                    },
                                                    {
                                                        num: '2',
                                                        namesys: '',
                                                        nameinputname: '',
                                                        nameinputfm: '',
                                                        nameuser: '',
                                                        currstatus: '',
                                                        license: '',
                                                        FM_CODE: ''
                                                    },
                                                    {
                                                        num: '3',
                                                        namesys: '',
                                                        nameinputname: '',
                                                        nameinputfm: '',
                                                        nameuser: '',
                                                        currstatus: '',
                                                        license: '',
                                                        FM_CODE: ''
                                                    },
                                                    {
                                                        num: '4',
                                                        namesys: '',
                                                        nameinputname: '',
                                                        nameinputfm: '',
                                                        nameuser: '',
                                                        currstatus: '',
                                                        license: '',
                                                        FM_CODE: ''
                                                    }
                                                ],
                                                CAMERA_DATA:
                                                    {
                                                        STATUS: '',
                                                        NAME: '',
                                                        IP: '',
                                                        URL: ''
                                                    },
                                                MQTT_DATA:
                                                    {
                                                        STATUS: '',
                                                        SERVER: '',
                                                        PORT: '',
                                                        TOPIC: '',
                                                        FM_ALARM_CODE_ON: '',
                                                        FM_ALARM_CODE_OFF: ''
                                                    }
                                                });
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
    useEffect(() => {
        const getSites = async () => {
        await axios.get(URL_ALL_SITES)
            .then( req_response => {
                const data_sites = req_response.data.data;
                const newArray = [...data_sites];
                setStateAllSite(newArray);
                setStateSelectedSite(newArray[0]);
            })
    }
    getSites();
        }, [stateNisySave]);
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  const handleAddSite = () => {
    delete stateSelectedSite['_id'];
    backUpdateInsertSite(stateSelectedSite);
    setStateNisySave(Math.floor(Math.random() * 10));
  };
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  const handleModalShow = () => {
    setStateModal(true);
  };
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  const handleModalClose = cancelorsave => {
    setStateModal(false);
    if (cancelorsave === 'cancel'){
        console.log('cancelled');
    }
    else if (cancelorsave === 'save') {
        // send the data in the modal to the backend
        backUpdateSite(stateSelectedSite, stateSelectedSite.SITE_DATA.ID);
        setStateNisySave(Math.floor(Math.random() * 10));

        // we want to send user action to backend for archives
        const {llogin} = decodeTheCurrUserToken();
        const myDate = new Date();
        const objtoinsert = {
                                DATE_TIME: myDate.toUTCString(),
                                REASON: `The site ${stateSelectedSite.SITE_DATA.NAME}'s has been modified.`,
                                SITE: stateSelectedSite.SITE_DATA.NAME,
                                ACTION: 'Modify',
                                CONNECTED_USER: llogin
                            };
        backUpdateAtions(objtoinsert);
        setStateNisySave(Math.floor(Math.random() * 10));
    }
  };
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  const handleEditSite = objtoedit => {
    setStateSelectedSite(objtoedit);
    handleModalShow();
    };
// -------------------------------------------------------------------------------------------------
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  const handleCheckBoxes = (jsonsection, event) => {
  	const { name: ev_name, checked: ev_checked} = event.target;
  	
    if (ev_checked) {
            setStateSelectedSite({ ...stateSelectedSite, [jsonsection]: {...stateSelectedSite[jsonsection], [ev_name]: 'ON', },},);
            // let the save button in modal update the database using the current selected site from state, tsy react nga moa io ?
        }
        else if (!ev_checked) {
            setStateSelectedSite({ ...stateSelectedSite, [jsonsection]: {...stateSelectedSite[jsonsection], [ev_name]: 'OFF', },});
            // let the save button in modal update the database using the current selected site from state, tsy react nga moa io ?
        }

  };
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  const handleModalInputs = (jsonsection, event) => {
  	const { name: ev_name, value: ev_value} = event.target;
    setStateSelectedSite({ ...stateSelectedSite, [jsonsection]: {...stateSelectedSite[jsonsection], [ev_name]: ev_value, },},);
  };
// -------------------------------------------------------------------------------------------------
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  const handleCheckBoxesSensors = (jsonsection, currindex, event) => {
    const {checked: ev_checked} = event.target;

    if (ev_checked) {
        let arraytopush = [...stateSelectedSite[jsonsection]];
        arraytopush[currindex].license = 'ON';
        setStateSelectedSite({ ...stateSelectedSite, [jsonsection]: arraytopush,});
        // let the useEffect update the database using the current selected site from state, it is react no ?       
    }
    else if (!ev_checked) {
        let arraytopush = [...stateSelectedSite[jsonsection]];
        arraytopush[currindex].license = 'OFF';
        setStateSelectedSite({ ...stateSelectedSite, [jsonsection]: arraytopush,});
        // let the useEffect update the database using the current selected site from state, it is react no ?
    }
  };
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= this handle the sensors name, wired and wireless
  const handleModalInputsSensors = (jsonsection, currindex, event) => {
    const {name: ev_name, value: ev_value} = event.target;
    
    if (ev_name === 'nameuser') {
        let arraytopush = [...stateSelectedSite[jsonsection]]; // spread the current section WIRED or WIRELESS
        arraytopush[currindex].nameuser = ev_value; // change the current index of sensor to ev_value
        setStateSelectedSite({ ...stateSelectedSite, [jsonsection]: arraytopush,}); // set the entire array of current section to the new array
    }
    else if (ev_name === 'FM_CODE') {
        let arraytopush = [...stateSelectedSite[jsonsection]]; // spread the current section WIRED or WIRELESS
        arraytopush[currindex].FM_CODE = ev_value; // change the current index of sensor to ev_value
        setStateSelectedSite({ ...stateSelectedSite, [jsonsection]: arraytopush,}); // set the entire array of current section to the new array   
    }
  };
  // -------------------------------------------------------------------------------------------------
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  const handleDelete = objtodelete => {
    // when here, we already have a confirmation from the user, we can delete the object from ui and db
    // remove from state
    // remove from db via delete API
  };
  const handleGenerateUrl = site => {
    navigator.clipboard.writeText(`https://siscos2.ogur.net:5001/configdir/${site._id}.json`);
    toast.success('The configuration URL has been copied to clipboard.');
  };
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
    return (
      <Fragment>
            <Toaster
              position = "top-center"
              reverseOrder = {false}
            />
            {/*MODALMODALMODALMODALMODALMODALMODALMODALMODALMODALMODALMODALMODALMODALMODALMODALMODALMODALMODALMODALMODALMODALMODALMODALMODAL*/}
            <Modal show = {stateModal} onHide = {() => handleModalClose('cancel')} size = 'xl'>
              <Modal.Header closeButton>
                <Modal.Title>Edit {stateSelectedSite.SITE_DATA.NAME}'s details.</Modal.Title>
              </Modal.Header>
              
              <Modal.Body>
              <div className = "card-group">
      
                  <div className = "card">
                    <div className = "card-body">
                      {/*=-=-=-=-=-=-=-=-=-=-=-=*/}
                      <ul className = "list-group list-group-flush">
                      
                      {/*------------------*/}
                        <div className = "fw-bold list-group-item-secondary input-group-text">
                            General site settings:
                        </div>
{/*------------------------------------------------------------------------------------------------------*/}
                      <li className = "list-group-item">
                        <ul className = "list-group list-group-flush">
                          <li className = "list-group-item">

                            {/*------------------------------------------------------------------------------------------------------*/}
                            <div className = "container">
                                  <div className = "row align-items-start">
                                    <div className = "col">{/*------------------*/}
                                      
                                              <div className = "input-group mb-1">
                                                <div className = "input-group-text" style={{width: 180}}>Name</div>
                                                <input
                                                    type = "text"
                                                    name = "NAME"
                                                    className = "ms-1 me-1 form-control"
                                                    aria-label="Text input with checkbox"
                                                    onChange = {(event) => handleModalInputs('SITE_DATA', event)}
                                                    defaultValue = {stateSelectedSite.SITE_DATA.NAME}
                                                />
                                              </div>
                                              {/*====================================*/}
                                              <div className = "input-group mb-1">
                                                <div className = "input-group-text" style={{width: 180}}>Latitude</div>
                                                <input
                                                    type = "label"
                                                    name = "GPS_LAT"
                                                    className = "ms-1 me-1 form-control"
                                                    aria-label="Text input with checkbox"
                                                    onChange = {(event) => handleModalInputs('SITE_DATA', event)}
                                                    defaultValue = {stateSelectedSite.SITE_DATA.GPS_LAT}
                                                />
                                              </div>
                                              {/*====================================*/}
                                              <div className = "input-group mb-1">
                                                <div className = "input-group-text" style={{width: 180}}>Longitude</div>
                                                <input
                                                    type = "label"
                                                    name = "GPS_LON"
                                                    className = "ms-1 me-1 form-control"
                                                    aria-label="Text input with checkbox"
                                                    onChange = {(event) => handleModalInputs('SITE_DATA', event)}
                                                    defaultValue = {stateSelectedSite.SITE_DATA.GPS_LON}
                                                />
                                              </div>
                                              {/*====================================*/}
                                              <div className = "input-group mb-1">
                                                <div className = "input-group-text" style={{width: 180}}>IP</div>
                                                <input
                                                    type = "label"
                                                    name = "IP"
                                                    className = "ms-1 me-1 form-control"
                                                    aria-label="Text input with checkbox"
                                                    onChange = {(event) => handleModalInputs('SITE_DATA', event)}
                                                    defaultValue = {stateSelectedSite.SITE_DATA.IP}
                                                />
                                              </div>
                                    </div>{/*---------END COL---------*/}



                                    <div className = "col">{/*------------------*/}
                                      
                                                <div className = "input-group mb-1">
                                                <div className = "input-group-text" style={{width: 180}}>Monitor this site</div>
                                                <div className="form-check form-switch ms-3">
                                                  <input
                                                    style = {{width: 55, height: 30}}
                                                    onChange = {(event) => handleCheckBoxes('SITE_DATA', event)}
                                                    name = "MONITORED"
                                                    className = "form-check-input"
                                                    type = "checkbox"
                                                    defaultChecked = {stateSelectedSite.SITE_DATA.MONITORED === 'ON' ? true : false}
                                                  />
                                                </div>
                                                </div>

                                                <div className = "input-group mb-1">
                                                <div className = "input-group-text" style={{width: 180}}>SMS alerts</div>
                                                <div className="form-check form-switch ms-3">
                                                  <input
                                                    style = {{width: 55, height: 30}}
                                                    onChange = {(event) => handleCheckBoxes('SITE_DATA', event)}
                                                    name = "ALERT_SMS"
                                                    className = "form-check-input"
                                                    type = "checkbox"
                                                    defaultChecked = {stateSelectedSite.SITE_DATA.ALERT_SMS === 'ON' ? true : false}
                                                  />
                                                </div>
                                                </div>

                                                <div className = "input-group mb-1">
                                                <div className = "input-group-text" style={{width: 180}}>E-MAIL alerts</div>
                                                <div className="form-check form-switch ms-3">
                                                  <input
                                                    style = {{width: 55, height: 30}}
                                                    onChange = {(event) => handleCheckBoxes('SITE_DATA', event)}
                                                    name = "ALERT_EMAIL"
                                                    className = "form-check-input"
                                                    type = "checkbox"
                                                    defaultChecked = {stateSelectedSite.SITE_DATA.ALERT_EMAIL === 'ON' ? true : false}
                                                  />
                                                </div>
                                                </div>
                                                {/*====================================*/}
                                                  <div className = "input-group mb-1">
                                                    <div className = "input-group-text" style={{width: 180}}>Voltage treshold (V)</div>
                                                    <input
                                                        type = "text"
                                                        name = "VOLT_TRES"
                                                        className = "ms-1 me-1 form-control"
                                                        aria-label="Text input with checkbox"
                                                        onChange = {(event) => handleModalInputs('SITE_DATA', event)}
                                                        defaultValue = {stateSelectedSite.SITE_DATA.VOLT_TRES}
                                                    />
                                                  </div>
                                    </div>{/*---------END COL---------*/}


                                    <div className = "col">{/*------------------*/}
                                              {/*====================================*/}
                                              <div className = "input-group mb-1">
                                                <div className = "input-group-text" style={{width: 180}}>Temp. treshold (°C)</div>
                                                <input
                                                    type = "text"
                                                    name = "TEMP_TRES"
                                                    className = "ms-1 me-1 form-control"
                                                    aria-label="Text input with checkbox"
                                                    onChange = {(event) => handleModalInputs('SITE_DATA', event)}
                                                    defaultValue = {stateSelectedSite.SITE_DATA.TEMP_TRES}
                                                />
                                              </div>

                                              {/*====================================*/}
                                              <div className = "input-group mb-1">
                                                <div className = "input-group-text" style={{width: 180}}>Fuel treshold (L)</div>
                                                <input
                                                    type = "text"
                                                    name = "FUEL_TRES"
                                                    className = "ms-1 me-1 form-control"
                                                    aria-label="Text input with checkbox"
                                                    onChange = {(event) => handleModalInputs('SITE_DATA', event)}
                                                    defaultValue = {stateSelectedSite.SITE_DATA.FUEL_TRES}
                                                />
                                              </div>



                                              <div className = "input-group mb-1">
                                                <div className = "input-group-text" style={{width: 180}}>Humi. treshold (%)</div>
                                                <input
                                                    type = "text"
                                                    name = "HUMI_TRES"
                                                    className = "ms-1 me-1 form-control"
                                                    aria-label="Text input with checkbox"
                                                    onChange = {(event) => handleModalInputs('SITE_DATA', event)}
                                                    defaultValue = {stateSelectedSite.SITE_DATA.HUMI_TRES}
                                                />
                                              </div>

                                              {/*====================================*/}
                                                  <div className = "input-group mb-1">
                                                    <div className = "input-group-text" style={{width: 180}}>Current treshold (A)</div>
                                                    <input
                                                        type = "text"
                                                        name = "CUR_TRES"
                                                        className = "ms-1 me-1 form-control"
                                                        aria-label="Text input with checkbox"
                                                        onChange = {(event) => handleModalInputs('SITE_DATA', event)}
                                                        defaultValue = {stateSelectedSite.SITE_DATA.CUR_TRES}
                                                    />
                                                  </div>
                                      
                                    </div>{/*---------END COL---------*/}



                                  </div>{/*---------END ITEMS-START---------*/}
                            </div>{/*---------END CONTAINER---------*/}
      {/*------------------------------------------------------------------------------------------------------*/}
                          
                          </li>
                        </ul>
                      </li>
{/*------------------------------------------------------------------------------------------------------*/}
                      <div className = "container">
                        <div className = "row align-items-start">
                          
                          <div className = "col">{/*------------------*/}
                            <li className = "list-group-item mt-4">
                              <div className = "fw-bold list-group-item-secondary input-group-text">
                                  Wired GPIO sensors
                              </div>
                            </li>
                            {/*------------------*/}
                            {/*00000000000000000000000000000000000*/}
                            <div className = "container" style = {{width: 360}}>
                              <div className = "row align-items-start">
                                
                                <div className = "col">
                                    <span className = "badge bg-secondary rounded-pill">Box name</span>
                                </div>
                                
                                <div className = "col">
                                    <span className = "badge bg-secondary rounded-pill">License</span>
                                </div>
                                
                                <div className = "col">
                                    <span className = "badge bg-secondary rounded-pill">Alert name</span>
                                </div>
                              </div>
                            </div>
                            {/*00000000000000000000000000000000000*/}
                            {stateSelectedSite.WIRED_DATA.map((currWiredSensor, currIndex) => (
                                  <ul key = {currWiredSensor.num} className = "list-group">
                                    <li className = "list-group-item">
                                      <div className = "container" style = {{width: 360}}>
                                        <div className = "row align-items-start">
                                          
                                          <div className = "col">
                                            <div className = "input-group-text" style={{width: 85}}>GPIO-{currWiredSensor.num}</div>
                                          </div>
                                          
                                          <div className = "col ms-3">
                                            <div className = "form-check form-switch">
                                              <input
                                                style = {{width: 55, height: 30}}
                                                onChange = {(event) => handleCheckBoxesSensors('WIRED_DATA', currIndex, event)}
                                                name = "license"
                                                className = "form-check-input"
                                                type = "checkbox"
                                                defaultChecked = {currWiredSensor.license === 'ON' ? true : false}
                                              />
                                            </div>
                                          </div>
                                          
                                          <div className = "col">
                                            <input
                                              style = {{width: 120}}
                                              name = "nameuser"
                                              onChange = {(event) => handleModalInputsSensors('WIRED_DATA', currIndex, event)}
                                              defaultValue = {currWiredSensor.nameuser}
                                              type = "text"
                                              className = "ms-1 form-control"
                                              aria-label = "Text input with checkbox"
                                            />
                                          </div>

                                        </div>
                                      </div>
                                      </li>
                                      </ul>
                                ))}
                          </div>{/*------------------*/}
                          
                          <div className = "col">{/*------------------*/}
                            <li className = "list-group-item  mt-4">
                              <div className = "fw-bold list-group-item-secondary input-group-text">
                                  FM 433MHz sensors
                              </div>
                            </li>
                            {/*------------------*/}
                            {/*00000000000000000000000000000000000*/}
                            <div className = "container" style = {{width: 550}}>
                              <div className = "row align-items-start">
                                
                                <div className = "col">
                                    <span className = "badge bg-secondary rounded-pill">Box name</span>
                                </div>
                                
                                <div className = "col">
                                    <span className = "badge bg-secondary rounded-pill">License</span>
                                </div>
                                
                                <div className = "col">
                                    <span className = "badge bg-secondary rounded-pill">Alert name</span>
                                </div>

                                <div className = "col">
                                    <span className = "badge bg-secondary rounded-pill">FM code</span>
                                </div>
                              </div>
                            </div>
                            {/*00000000000000000000000000000000000*/}
                            {stateSelectedSite.WIRELESS_DATA.map((currWirelessSensor, currIndex) => (
                                  <ul key = {currWirelessSensor.num} className = "list-group">
                                    <li className = "list-group-item">
                                      <div className = "container" style = {{width: 550}}>
                                        <div className = "row align-items-start">
                                          
                                          <div className = "col">
                                            <div className = "input-group-text" style={{width: 90}}>WL-IO-{currWirelessSensor.num}</div>
                                          </div>
                                          
                                          <div className = "col ms-4">
                                            <div className = "form-check form-switch">
                                              <input
                                                style = {{width: 55, height: 30}}
                                                onChange = {(event) => handleCheckBoxesSensors('WIRELESS_DATA', currIndex, event)}
                                                name = "license"
                                                className = "form-check-input"
                                                type = "checkbox"
                                                defaultChecked = {currWirelessSensor.license === 'ON' ? true : false}
                                              />
                                            </div>
                                          </div>
                                          
                                          <div className = "col">
                                            <input
                                              style = {{width: 120}}
                                              name = "nameuser"
                                              onChange = {(event) => handleModalInputsSensors('WIRELESS_DATA', currIndex, event)}
                                              defaultValue = {currWirelessSensor.nameuser}
                                              type = "text"
                                              className = "ms-1 form-control"
                                              aria-label = "Text input with checkbox"
                                            />
                                          </div>

                                          <div className = "col">
                                            <input
                                              style = {{width: 120}}
                                              name = "FM_CODE"
                                              onChange = {(event) => handleModalInputsSensors('WIRELESS_DATA', currIndex, event)}
                                              defaultValue = {currWirelessSensor.FM_CODE}
                                              type = "text"
                                              className = "ms-1 form-control"
                                              aria-label = "Text input with checkbox"
                                            />
                                          </div>


                                        </div>
                                      </div>
                                      </li>
                                      </ul>
                                ))}
                          </div>{/*------------------*/}    
                        
                        </div>
                      </div>
{/*------------------------------------------------------------------------------------------------------*/}
                      <li className = "fw-bold list-group-item mt-4">
                        <div className = "fw-bold list-group-item-secondary input-group-text">
                            MQTT config.
                          <div className="pull-right form-check form-switch ms-2">
                              <input
                                style = {{width: 55, height: 30}}
                                onChange = {(event) => handleCheckBoxes('MQTT_DATA', event)}
                                name = "STATUS"
                                className = "form-check-input"
                                type = "checkbox"
                                defaultChecked = {stateSelectedSite.MQTT_DATA.STATUS === 'ON' ? true : false}
                              />
                          </div>
                        </div>
                      </li>
                      {/*------------------*/}
                      <li className = "list-group-item">
                        {/*====================================*/}
                          <div className = "input-group mb-2">
                            <div className = "input-group-text" style={{width: 90}}>Server</div>
                            <input
                              type = "text"
                              name = "SERVER"
                              className = "ms-1 me-1 form-control"
                              aria-label = "Text input with checkbox"
                              onChange = {(event) => handleModalInputs('MQTT_DATA', event)}
                              defaultValue = {stateSelectedSite.MQTT_DATA.SERVER}
                            />

                            <div className = "input-group-text" style={{width: 60}}>Port</div>
                            <input
                              type = "text"
                              name = "PORT"
                              className = "ms-1 me-1 form-control"
                              aria-label = "Text input with checkbox"
                              onChange = {(event) => handleModalInputs('MQTT_DATA', event)}
                              defaultValue = {stateSelectedSite.MQTT_DATA.PORT}
                            />

                            <div className = "input-group-text" style={{width: 90}}>Topic</div>
                            <input
                              type = "text"
                              name = "TOPIC"
                              className = "ms-1 me-1 form-control"
                              aria-label = "Text input with checkbox"
                              onChange = {(event) => handleModalInputs('MQTT_DATA', event)}
                              defaultValue = {stateSelectedSite.MQTT_DATA.TOPIC}
                            />

                            <div className = "input-group-text" style={{width: 100}}>Alarm ON</div>
                            <input
                              type = "text"
                              name = "FM_ALARM_CODE_ON"
                              className = "ms-1 me-1 form-control"
                              aria-label = "Text input with checkbox"
                              onChange = {(event) => handleModalInputs('MQTT_DATA', event)}
                              defaultValue = {stateSelectedSite.MQTT_DATA.FM_ALARM_CODE_ON}
                            />

                            <div className = "input-group-text" style={{width: 100}}>Alarm OFF</div>
                            <input
                              type = "text"
                              name = "FM_ALARM_CODE_OFF"
                              className = "ms-1 me-1 form-control"
                              aria-label = "Text input with checkbox"
                              onChange = {(event) => handleModalInputs('MQTT_DATA', event)}
                              defaultValue = {stateSelectedSite.MQTT_DATA.FM_ALARM_CODE_OFF}
                            />

                          </div>
                        </li>
{/*------------------------------------------------------------------------------------------------------*/}
                      <li className = "fw-bold list-group-item mt-4">
                        <div className = "fw-bold list-group-item-secondary input-group-text">
                            Onsite camera
                          <div disabled = {true} className="pull-right form-check form-switch ms-2">
                              <input
                                style = {{width: 55, height: 30}}
                                onChange = {(event) => handleCheckBoxes('CAMERA_DATA', event)}
                                name = "STATUS"
                                className = "form-check-input"
                                type = "checkbox"
                                defaultChecked = {stateSelectedSite.CAMERA_DATA.STATUS === 'ON' ? true : false}
                              />
                          </div>
                        </div>
                      </li>
{/*------------------------------------------------------------------------------------------------------*/}
                      <li className = "list-group-item">
                        <ul className = "list-group list-group-flush">
                          <li className = "list-group-item">

                          {/*====================================*/}
                          <div className = "input-group mb-1">
                            <div className = "input-group-text" style={{width: 90}}>Name</div>
                            <input
                              type = "text"
                              name = "NAME"
                              className = "ms-1 me-1 form-control"
                              aria-label = "Text input with checkbox"
                              onChange = {(event) => handleModalInputs('CAMERA_DATA', event)}
                              defaultValue = {stateSelectedSite.CAMERA_DATA.NAME}
                            />

                            <div className = "input-group-text" style={{width: 90}}>IP</div>
                            <input
                              type = "text"
                              name = "IP"
                              className = "ms-1 me-1 form-control"
                              aria-label = "Text input with checkbox"
                              onChange = {(event) => handleModalInputs('CAMERA_DATA', event)}
                              defaultValue = {stateSelectedSite.CAMERA_DATA.IP}
                            />

                            <div className = "input-group-text" style={{width: 120}}>Stream URL</div>
                            <input
                              style={{width: 200}}
                              type = "text"
                              name = "URL"
                              className = "ms-1 me-1 form-control"
                              aria-label = "Text input with checkbox"
                              onChange = {(event) => handleModalInputs('CAMERA_DATA', event)}
                              defaultValue = {stateSelectedSite.CAMERA_DATA.URL}
                            />
                          </div>
                          {/*====================================*/}
                          </li>
                        </ul>
                      </li>
{/*------------------------------------------------------------------------------------------------------*/}
              </ul>
              </div>
              </div>
              </div>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="secondary" onClick = {() => handleModalClose('cancel')}>
                  Cancel
                </Button>
                <Button variant="primary" onClick = {() => handleModalClose('save')}>
                  Save Changes
                </Button>
              </Modal.Footer>

            </Modal>

            {/*MODALMODALMODALMODALMODALMODALMODALMODALMODALMODALMODALMODALMODALMODALMODALMODALMODALMODALMODALMODALMODALMODALMODALMODALMODAL*/}


            <h5 className = "text-center mt-5">
                Sites: 
                <span className = "ms-2 fs-6 badge bg-primary">
                    {stateAllSites.length}
                </span>

                {/* <span>
                    <button type="button" title = "Add a new site" className="m-1 btn btn-sm btn-primary">
                        <i
                            className = "fa fa-plus"
                            aria-hidden = "true"
                            onClick = { () => {
                                                      const confirmBox = window.confirm(`Do you really want to add a new site ?`);
                                                      if (confirmBox === true)
                                                          {handleAddSite()}
                                                    }}></i>
                    </button>
                </span> */}
                
            </h5>
            <table className = 'mt-3 container table  table-bordered'>
          
                  <thead>
                    <tr className = "text-center align-middle ">
                      <th><span className='badge fw-normal fs-6 text-dark'>Name</span></th>
                      <th><span className='badge fw-normal fs-6 text-dark'>IP address</span></th>
                      <th><span className='badge fw-normal fs-6 text-dark'>Temperature treshold</span></th>
                      <th><span className='badge fw-normal fs-6 text-dark'>Humidity treshold</span></th>

                      <th><span className='badge fw-normal fs-6 text-dark'>Voltage treshold</span></th>
                      <th><span className='badge fw-normal fs-6 text-dark'>Current treshold</span></th>

                      <th><span className='badge fw-normal fs-6 text-dark'>Fuel treshold</span></th>
                      <th><span className='badge fw-normal fs-6 text-dark'>Gateway</span></th>
                      <th><span className='badge fw-normal fs-6 text-dark'>Onsite camera</span></th>
                      <th><span className='badge fw-normal fs-6 text-dark'>SMS</span></th>
                      <th><span className='badge fw-normal fs-6 text-dark'>E-Mail</span></th>
                      <th>
                        <span>
                          <input
                            // onKeyDown = {handleFilterClear}
                            // onChange = { handleFilter }
                            type="search"
                            placeholder="Filter sites..."
                            style={{width: 280}}
                            className = "form-control"
                          />
                        </span>
                        {/*<span>
                            <button type="button" title = "Export selected site(s)" className="m-1 btn btn-outline-secondary">
                                <i className = "fa fa-download" aria-hidden = "true"></i>
                            </button>
                        </span>*/}
                      </th>
                    </tr>
                  </thead>
                  
                  <tbody>
                    { stateAllSites.map( (site) => (
                      <tr  className = "text-center align-middle " key = { site.SITE_DATA.IP }>
                        <td>{ site.SITE_DATA.NAME }</td>
                        <td>{ site.SITE_DATA.IP }</td>
                        <td>{ site.SITE_DATA.TEMP_TRES } °</td>
                        <td>{ site.SITE_DATA.HUMI_TRES } %</td>
                        
                        <td>{ site.SITE_DATA.VOLT_TRES } V</td>
                        <td>{ site.SITE_DATA.CUR_TRES } A</td>

                        <td>{ site.SITE_DATA.FUEL_TRES } L</td>
                        <td>{ site.SITE_DATA.GATEWAY }</td>
                        {/* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */}
                        <td>
                          {site.CAMERA_DATA.STATUS === 'ON' ? <span className = "fw-normal fs-6 badge bg-info">Enabled</span> : <span className = "fw-normal fs-6 badge bg-secondary">Disabled</span>}
                        </td>
                        <td>
                          {site.SITE_DATA.ALERT_SMS === 'ON' ? <span className = "fw-normal fs-6 badge bg-info">Enabled</span> : <span className = "fw-normal fs-6 badge bg-secondary">Disabled</span>}
                        </td>
                        <td>
                          {site.SITE_DATA.ALERT_EMAIL === 'ON' ? <span className = "fw-normal fs-6 badge bg-info">Enabled</span> : <span className = "fw-normal fs-6 badge bg-secondary">Disabled</span>}
                        </td>
                        {/* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */}
                        <td>
                          <button
                                  onClick = { () => handleGenerateUrl(site) }
                                  className = "btn btn-link text-dark btn-sm ms-2">
                                  Generate site URL
                          </button>

                          <button
                                  onClick = { () => handleEditSite(site) }
                                  className = {`${props.p_currUser.rrole === 'admin' ? null : 'disabled'} btn btn-warning btn-sm ms-2`}>
                                  Edit
                          </button>
                          <button
                                  className = {`${props.p_currUser.rrole === 'admin' ? null : 'disabled'} btn btn-danger btn-sm m-1`}
                                  onClick = { () => {
                                                      const confirmBox = window.confirm(`Do you really want to delete the site: ${site.SITE_DATA.NAME}  with IP: ${site.SITE_DATA.IP} ?`);
                                                      if (confirmBox === true)
                                                          {handleDelete(site)}
                                                    }}>
                                  Delete
                          </button>
                          
                          {/* <input className = "form-check-input align-middle ms-5" type="checkbox" defaultValue = "" id="flexCheckDefault" /> */}
                        </td>
                        {/* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */}
                      </tr>
                      
                    )) }
                  </tbody>
                </table>
      </Fragment>
      );
  }
 // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
 export default SettingsSites;
