import React, { Fragment } from 'react';
import { Modal, Button } from 'react-bootstrap';

import ImgUser from '../assets/user.webp';
import '../index.css';

import {decodeTheCurrUserToken} from '../getcurrentuser';
import {backUpdateUser, backDeleteUser, backUpdateAtions} from '../sendtobackend';

import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';

import axios from 'axios';
import { useState, useEffect } from 'react';

import urlSite from './ConfigUrl';

// const URL_ALL_USERS = "http://node23942-ogursec-back.rag-cloud.hosteur.com:11036/api/getallusers";
// const URL_ALL_USERS = "https://siscos2.ogur.net:5001/api/getallusers";
const URL_ALL_USERS = urlSite.URL_ALL_USERS;

const SettingsUser = (props) => {
  const [stateAllUsers, setStateAllUser] = useState([]);
  const [stateModalState, setStateModal] = useState(false);
  const [stateObjToEditForForm, setStateObjToEditForForm] = useState({});
  const [stateNisySave, setStateNisySave] = useState(0);
  const [stateSaveButtonStatus, setStateSaveButton] = useState('disabled');
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  useEffect(() => {
    const getUsers = async () => {
      await axios.get(URL_ALL_USERS)
        .then( req_response => {
          const data_users = req_response.data.data;
          const newArray = [...data_users];
          setStateAllUser(newArray);
          console.log('nisy get');
          console.log(newArray);
        })
    }
    getUsers();
        }, [stateNisySave]);
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  const handleModalShow = () => {
    setStateModal(true);
  };
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  const handleModalClose = cancelorsave => {
    setStateModal(false);
    if (cancelorsave === 'cancel'){
        console.log('cancelled');
        setStateSaveButton('disabled');
    }
    else if (cancelorsave === 'save') {
      // remove the password property with ...rest; get data from object, remove PASSWORD, let the rest in the 'rest' variable
      const {PASSWORD, ...rest} = stateObjToEditForForm;
      backUpdateUser(rest, rest.EMAIL);
      setStateNisySave(Math.floor(Math.random() * 10));
      setStateSaveButton('disabled');

      const {llogin} = decodeTheCurrUserToken();
      const myDate = new Date();
      const objtoinsert = {
                            DATE_TIME: myDate.toUTCString(),
                            REASON: `The user ${stateObjToEditForForm.EMAIL}'s details has been updated.`,
                            SITE: 'NOC_UI_UPDATE',
                            ACTION: 'Modify',
                            CONNECTED_USER: llogin
                          };
      backUpdateAtions(objtoinsert);
    }
  };
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  const handleEditUser = objtoedit => {
    setStateObjToEditForForm(objtoedit);
    handleModalShow();
  };
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  const handleEditModalInputs = (event) => {
    const { name: ev_name, value: ev_value} = event.target;
    setStateObjToEditForForm({ ...stateObjToEditForForm, [ev_name]: ev_value, },);
    setStateSaveButton('enabled');
  };
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  const handleDeleteUser = (objtodelete) => {
    // remove it from the db
    backDeleteUser(objtodelete);
    // remove it from the UI
    setStateNisySave(Math.floor(Math.random() * 10));

  };
  const handleresetPass = (user) => {
    console.log('reset the password of: ', user);
  };
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
    return (
      <Fragment>
            <Modal show = {stateModalState} onHide = {() => handleModalClose('cancel')} size = 'xl'>
              <Modal.Header closeButton>
                <Modal.Title>Edit {stateObjToEditForForm.LOGIN}'s details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <div>
                  <div className = "container rounded bg-white mt-1 mb-5">
                      <div className = "row">
                          <div className = "col-md-3 border-right">
                              <div className = "d-flex flex-column align-items-center text-center p-3 py-5">
                                  <img alt = "To tell the user it is for user"
                                      className = "rounded-circle mt-1"
                                      width="150px"
                                      src= {ImgUser} />
                                      <span className = "my-anarana">{stateObjToEditForForm.LOGIN}</span>
                                      <span className = "text-black-50">{stateObjToEditForForm.ROLE}</span>
                                      <span> </span>
                              </div>
                          </div>
                          <div className = "col-md-8 border-right">
                              <div className = "p-3 py-5">
                                  
                                  <div className = "row mt-2">
                                      <div className = "col-md-12"><label className = "labels">Name</label>
                                        <input
                                          type = "text"
                                          name = "LOGIN"
                                          className = "form-control"
                                          onChange = {handleEditModalInputs}
                                          value = {stateObjToEditForForm.LOGIN}
                                        />
                                      </div>
                                  </div>
                                  
                                  <div className = "row mt-3">
                                      
                                      <div className = "col-md-12">
                                          <label className = "labels">Email (can not be modified)</label>
                                          <input
                                            type = "text"
                                            name = "EMAIL"
                                            readOnly
                                            className = "form-control"
                                            value = {stateObjToEditForForm.EMAIL}
                                          />
                                      </div>
                                      
                                      <div className = "col-md-12">
                                          <label className = "labels">Phone number</label>
                                          <input
                                            type = "text"
                                            name = "NUMBER"
                                            className = "form-control"
                                            onChange = {handleEditModalInputs}
                                            defaultValue = {stateObjToEditForForm.NUMBER}
                                          />
                                      </div>
                                      
                                      <div className = "col-md-12">
                                          <label className = "labels mt-2">Role</label>
                                          <select
                                            name = "ROLE"
                                            className = "form-select"
                                            aria-label = "Default select example"
                                            onChange = {handleEditModalInputs}
                                          >
                                            <option defaultValue>{stateObjToEditForForm.ROLE}</option>
                                              {stateObjToEditForForm.ROLE === 'admin' ?
                                            <option defaultValue = "2">user</option> : <option defaultValue = "1">admin</option>}
                                          </select>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  </div>
              </Modal.Body>
              <Modal.Footer>
              <Button variant = "secondary" onClick={() => handleModalClose('cancel')}>
                  Cancel
                </Button>
                <Button className = {stateSaveButtonStatus} variant = "primary" onClick={() => handleModalClose('save')}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>


            <h5 className = "text-center mt-5">
              Users: 
              <span className = "ms-2 badge fs-6 bg-primary">
                {stateAllUsers.length}
              </span>
            </h5>
            <table className = 'mb-5 container table  table-bordered'>
          
                  <thead>
                    <tr className = "text-center align-middle ">
                      <th><span className='badge text-dark fw-normal fs-6'>Name</span></th>
                      <th><span className='badge text-dark fw-normal fs-6'>E-mail</span></th>
                      <th><span className='badge text-dark fw-normal fs-6'>Role</span></th>
                      <th><span className='badge text-dark fw-normal fs-6'>Phone number</span></th>
                      <th><span className='badge text-dark fw-normal fs-6'>Last login</span></th>
                      <th><span className='badge text-dark fw-normal fs-6'>Actions</span></th>
                    </tr>
                  </thead>
                  
                  <tbody>
                    { stateAllUsers.map( (user) => (
                      <tr  className = {`${user.ROLE === 'admin' ? "table-success" : null} align-middle text-center `} key = { user.EMAIL }>
                        <td>
                          { user.LOGIN }
                        </td>

                        <td>{ user.EMAIL }</td>
                        <td>{ user.ROLE }</td>
                        <td>{ user.NUMBER }</td>
                        <td>{ user.LAST_LOGIN }</td>
                        <td style = {{width: 280}}>

                          <button
                            onClick = { () => handleEditUser(user) }
                            className = {`${props.p_currUser.rrole === 'admin' ? null : 'disabled'} btn btn-warning btn-sm ms-2`}>
                            Edit
                          </button>
                          
                          {props.p_currUser.llogin === user.LOGIN
                          ? <button
                              className = "btn btn-link text-dark btn-sm ms-2"
                              onClick = { () => handleresetPass(user) }>
                                Reset password
                            </button>
                          : null}
                          
                          <button
                                  className = {`${props.p_currUser.rrole === 'admin' ? null : 'disabled'} btn btn-danger btn-sm m-1`}
                                  onClick = { () => {
                                                      const confirmBox = window.confirm(`Do you really want to delete the user: ${user.EMAIL} ?`);
                                                      if (confirmBox === true)
                                                          {handleDeleteUser(user)}
                                                    }}>
                                  Delete
                          </button>
                          
                          {/* <input className = "form-check-input align-middle ms-5" type="checkbox" defaultValue = "" id="flexCheckDefault" /> */}
                        </td>

                      </tr>
                      
                    )) }
                  </tbody>
                </table>

      </Fragment>
      );
  }
 // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
 export default SettingsUser;
