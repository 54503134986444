let domaine = '';

if ( process.env.REACT_APP_ENV === 'development' ) {
	domaine = 'https://siscos.ogur.net:5001';
} else if ( process.env.REACT_APP_ENV === 'production' ) {
	domaine = 'https://siscos.ogur.net:5001';
}

let urlSite = {
	'URL_API_GRAPHES': domaine + '/api/getdataforgraphes',
	'URL_ALL_USERACTIONS': domaine + '/api/getalluseractions',
	'URL_API_GRAPHES_FUEL': domaine + '/api/getdataforgraphesfuel',
	'URL_API_GRAPHES_TM': domaine + '/api/getdataforgraphestemp',
	'URL_API_GRAPHES_HUM': domaine + '/api/getdataforgrapheshumi',
	'URL_API_GRAPHES_TOTAL': domaine + '/api/getdataforgrapheshumi',
	'URL_API_GRAPHES_VOLT': domaine + '/api/getdataforgraphesvolt',
	'URL_API_SINGIN': domaine + '/api/login',
	'URL_ALL_SITES': domaine + '/api/getallsites',
	'URL_ALL_USERS': domaine + '/api/getallusers',
	'URL_ONE_SITE': domaine + '/api/getonesite',
	'URL_API_SIGNUP': domaine + '/api/register',
	'URL_ALL_SITES_NODE': domaine + '/api/getallsites',
	'URL_ALL_ALERTS': domaine + '/api/getallalerts'
};

export default urlSite;