import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';

import urlSite from './ConfigUrl';


import ImgMap from '../assets/map.png';
import '../index.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';

// const URL_ALL_SITES = "https://siscos2.ogur.net:5001/api/getallsites";
const URL_ALL_SITES = urlSite.URL_ALL_SITES;

const position = [-18.8801126, 47.506256]

const Map = (props) => {
    const navigate = useNavigate();
    
    if ( !localStorage.getItem('siscos30') ) {
        setTimeout(function() {
          navigate('/');
        }, 100);
    }

	const [stateAllSites, setStateAllSite] = useState([]);
	// =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
	const getSites =  async () => {
	   await axios.get(URL_ALL_SITES).then( req_response => {
	   const data_sites = req_response.data.data;
	        setStateAllSite(data_sites);
	      })
	  }
    // =-=-=-=-=-=-=-=-=-=-=-=-=-=--=-=-=-=-=-=-=-=-=-=
    useEffect(() => {
        toast.promise(getSites(), {
                                loading: "Compiling data from database...",
                                error: "Oh oh!, there was an error while fetching the data",
                                success: "Successfully got data."
                                  });
                    }, []);
    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  	return (

  		<div className = "leaflet-container">
			<Toaster position = "top-center" reverseOrder = {false} />
			<MapContainer center={position} zoom = {20} scrollWheelZoom = {true}>
			    <TileLayer
			      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
			      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
			    />
			    <Marker position = {position}>
			      <Popup>
			        Andraharo, site.
			      </Popup>
			    </Marker>
			  </MapContainer>
		</div>
	);
}
 // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
 export default Map;
 


//  import marker from '../../Assets/icons/Location.svg';
// import { Icon } from 'leaflet'
// const myIcon = new Icon({
//  iconUrl: marker,
//  iconSize: [32,32]
// })

// <MapContainer center={value} zoom={13} scrollWheelZoom={false}>
//       <TileLayer
//         attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
//         url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//       />
//       <Marker position={value} icon={myIcon}>
//         <Popup>
//         Location :{data}
//         </Popup>
//       </Marker>
//     </MapContainer>
