import React, {useEffect, useState, Fragment} from 'react';
import DatePicker from "react-datepicker";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';


import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Site Andraharo',
    },
  },
};


const URL_API_GRAPHES = 'https://siscos2.ogur.net:5001/api/getdataforgraphescur';

const GraphCur = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [stateDataGrapheCur, setStateDataGrapheCur] = useState(props.p_datacur);

  // we need to handle the time axis localy like the data when the user changes the date
  const [stateDataGrapheLabels, setStateDataGrapheLabels] = useState(props.p_tempLabels);


  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
	const sendReqToBackForGrapheDataCur = async (dateazo) => {
    setStartDate(dateazo);
    // build the date object
    let yearForDate = dateazo.toLocaleString("default", { year: "numeric" });
    let monthForDate = dateazo.toLocaleString("default", { month: "2-digit" });
    let dayForDate = dateazo.toLocaleString("default", { day: "2-digit" });

    const dateToSend = yearForDate + monthForDate + dayForDate;
    console.log(dateToSend);

    const cors = {mode:'cors'};
    const params = { D_DATE: dateToSend};
    await axios.post(URL_API_GRAPHES, params, cors)
        .then(response => {

          // time for all data
          let valuesLabels = response.data.result.map((item) => item.D_TIME);
          setStateDataGrapheLabels(valuesLabels);
          
          let values = response.data.result.map((item) => item.D_CURRENT);
          setStateDataGrapheCur(values);
          
          if (response.data.msg === 'SUCCESS_GRAPH') {
            console.log('got_data_for_graph');
        }
        else if (response.data.msg === 'NO_DATA') {
            notify('There is no current data for this date.', 'baaad');
        }
        })
        .catch(error => {
          notify('There is no data for this date.', 'baaad');
        });
    }
    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
    useEffect(() => {
      // set the data of cur from props
      setStateDataGrapheCur(props.p_datacur);

      // set the time of cur from props
      setStateDataGrapheLabels(props.p_tempLabels);
    }, [props.p_datacur]);
    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=    
    const notify = (thigstonotify, goodorbad) => {
      if (goodorbad === 'goood') {
          toast.success(thigstonotify);
      }
      else if (goodorbad === 'baaad') {
          toast.error(thigstonotify);
      }
    };
// =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=

  return (
    <Fragment>

      <Toaster position = "top-center" reverseOrder = {false} />

                              {/*-----------------------------------------*/}
                              <div className = "text-center rounded">
                                  
                                  {props.p_withpicker ? <div className = "text-center text-muted">
                                    <DatePicker
                                      className = "form-control widh_picker"
                                      portalId = "root-portal"
                                      selected = {startDate}
                                      onChange = {(date) => sendReqToBackForGrapheDataCur(date)}
                                    />
                                  </div> : null}
                              {/*-----------------------------------------*/}
                                  <span className = 'card border-info m-1' style={{width: 640, display: 'inline-block'}}>
                                    <div className = "align-items-center">

                                      <span className = "row align-items-center">
                                              <Line
                                                    options = {options}
                                                    data = {{
                                                        labels: props.p_tempLabels,                                                      
                                                        datasets: [
                                                          {
                                                            label: 'Current A',
                                                            // ireto soloina valeur av any anaty base avy eo refa tonga ny data av an am back ny site
                                                            data: stateDataGrapheCur,
                                                            borderColor: 'rgb(53, 162, 235)',
                                                            backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                                          },
                                                        ],
                                                      }
                                                    }
                                              />
                                      </span>
                                    </div>

                                  </span>
                              </div>
                              {/*-----------------------------------------*/}
    </Fragment>
  );
}
export default GraphCur;
// =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=