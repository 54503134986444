import React, {Fragment, useState} from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import ImgUser from '../assets/user.webp';

import '../index.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';

const Header = (props) => {
	const navigate = useNavigate();
	// =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
	const { p_currUser } = props;
	const [isActive, setIsActive] = useState(false);
	// =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
	const doLogOut = () => {
		localStorage.removeItem('siscos30');
		navigate(0);
	};

	// =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
	const clickMenu = () => {
		setIsActive(current => !current);
	};	

	// =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
	return (
			<Fragment>
				<nav className = "sticky-top shadow-lg rounded navbar navbar-expand-lg navbar-light bg-light">
					<div className = "container-fluid">
					    
					    {/*<h3><span className = "badge bg-black rounded-pill">SiSCoS v3.0</span></h3>*/}
					    
					    <button 
					    	onClick = {clickMenu} 
					    	className ="navbar-toggler"
					    	type = "button" 
					    	data-bs-toggle = "collapse" 
					    	data-bs-target = "#navbarSupportedContent" 
					    	aria-controls = "navbarSupportedContent" 
					    	aria-expanded = "false" 
					    	aria-label = "Toggle navigation"
					    >
					      <span className = "navbar-toggler-icon"></span>
					    </button>

					    <div 
					    	className = {isActive ? 'topnav collapse navbar-collapse shows' : 'topnav collapse navbar-collapse'} 
					    	id = "navbarSupportedContent"
					    >
					      <ul className = "navbar-nav me-auto mb-2 mb-lg-0">
			        
					        {p_currUser.llogin ? <li className = "nav-item">
					          <NavLink className = "nav-link" aria-current="page" to = "/dashboard"><i style = {{color: 'grey'}} className = "fa fa-bar-chart"></i> Dashboard</NavLink>
					        </li> : null}

					        {p_currUser.llogin ? <li className = "nav-item">
					          <NavLink className = "nav-link" aria-current="page" to = "/sites"><i style = {{color: 'grey'}} className = "fa fa-sitemap"></i> Sites</NavLink>
					        </li> : null}

					        {p_currUser.llogin ? <li className = "nav-item">
					          <NavLink className = "nav-link" aria-current="page" to = "/map"><i style = {{color: 'grey'}} className = "fa fa-globe"></i> Map</NavLink>
					        </li> : null}

					        {p_currUser.llogin ? <li className = "nav-item">
					          <NavLink className = "nav-link" to = "/archives"><i style = {{color: 'grey'}} className = "fa fa-database"></i> Archives</NavLink>
					        </li> : null}
					        
					        {p_currUser.llogin ? <li className = "nav-item">
					          <NavLink className = "nav-link" aria-current="page" to = "/settings"><i style = {{color: 'grey'}} className = "fa fa-cogs"></i> Settings</NavLink>
					        </li> : null}


					      </ul>

					      <ul className = "navbar-nav ml-auto mb-2 mb-lg-0">
					        
						  {p_currUser.llogin ? null: <li className = "nav-item">
					          <NavLink className = "nav-link" to = "/"><i className = "fa fa-sign-in"></i> Log in</NavLink>
					        </li>}

					        {p_currUser.llogin ? null : <li className = "nav-item">
					          <NavLink className = "nav-link" to = "/signup"><i className = "fa fa-user-plus"></i> Sign up</NavLink>
					        </li>}


							{p_currUser.llogin ? <li className = "nav-item">
					          	<NavLink
					          		className = "nav-link me-2"
					          		to = "/users"
					          		title = "Click here to edit user settings"
					          	>
					          	<img 
					          		alt = "Click here to edit user settings"
                                    className = "rounded-circle me-3"
                                    width="35px"
                                    src= {ImgUser}
                                    title = "Click here to edit user settings"
                                />
					          	{p_currUser.llogin}
					          </NavLink>
					        </li> : null}

					        
					        {p_currUser.llogin ? <li className = "nav-item" title = "Log out" onClick = {doLogOut}>
					          <NavLink className = "nav-link" to = "/">
					          	<i style = {{color: 'red'}} className = "mt-2 fa fa-2x fa-power-off"></i>
                              </NavLink>
                                                    
					        </li> : null}


					        {/*
					        {p_currUser.llogin ? <li className = "nav-item">
					          <NavLink onClick = { doLogOut } className = "nav-link" to = "/"><i className = "fa-2x fa fa-sign-out"></i> Log out</NavLink>
					        </li> : null}*/}

					       </ul>

					     
					    </div>
					</div>
				</nav>
      		</Fragment>
		)
}
export default Header;
