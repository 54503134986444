import React, { useState, useEffect, Fragment } from 'react';
import { MDBDataTable } from 'mdbreact';

import '../index.css';

import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';

import axios from 'axios';
import urlSite from './ConfigUrl';

// const URL_ALL_UserActions = "http://node23942-ogursec-back.rag-cloud.hosteur.com:11036/api/getalluseractions";

// const URL_ALL_USERACTIONS = "https://siscos2.ogur.net:5001/api/getalluseractions";
const URL_ALL_USERACTIONS = urlSite.URL_ALL_USERACTIONS;

const ArchivesUserActions = () => {
  const [stateAllUserActions, setStateAllUserActions] = useState([]);
  const [stateDataUserActions, setStateDataUserActions] = useState({});
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  useEffect(() => {
    const getUserActions = async () => {
      await axios.get(URL_ALL_USERACTIONS).then((req_response) => {
        const data_useractions = req_response.data.data;
        setStateAllUserActions(data_useractions);
      });
    };
    getUserActions();
  }, []);
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  useEffect(() => {
    const buildTable = () => {
      const data_row_useractions = {
          // columns are fixed, they do not change in time
            columns: [
                      { label: 'Action date', field: 'DATE_TIME', sort: 'asc'},
                      { label: 'Reason', field: 'REASON', sort: 'asc'},
                      { label: 'Site', field: 'SITE', sort: 'asc'},
                      { label: 'Action', field: 'ACTION', sort: 'asc'},
                      { label: 'User', field: 'CONNECTED_USER', sort: 'asc'}
                    ],
          // rows are dynamic, we handle them with state
            rows: stateAllUserActions
          };
        setStateDataUserActions(data_row_useractions);
    };
    buildTable();
  }, [stateAllUserActions]);
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
    return (
      <Fragment>
            <MDBDataTable
              className = "mt-5"
              striped
              info = {false}
              bordered
              entriesLabel = {<h5>User actions: <span className = "badge bg-primary">{stateAllUserActions.length}</span></h5>}
              entriesOptions = {[10, 20, 30, 40, 50, 100, 500]}
              searchLabel = 'Filter actions...'
              data = {stateDataUserActions}
            />
      </Fragment>
      );
  }
 // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
 export default ArchivesUserActions;
