import React, { useState, Fragment } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import urlSite from './ConfigUrl';

import LogoOgur from '../assets/logo_ogur2.png';
import '../forms.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';

// const URL_API_SINGIN = 'http://node23942-ogursec-back.rag-cloud.hosteur.com:11036/api/login';
// const URL_API_SINGIN = 'https://siscos2.ogur.net:5001/api/login';
// const URL_API_SINGIN = 'http://localhost:5001/api/login';
const URL_API_SINGIN = urlSite.URL_API_SINGIN;



const Login = () => {
    const navigate = useNavigate();
        
    if ( localStorage.getItem('siscos30') ) {
        setTimeout(function() {
            navigate('/dashboard');
        }, 5);
    } 
    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
    const [dataForm, setStateForm] = useState({
        emailField: '',
        passwordField: ''
    });
    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
    const handleFields = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        setStateForm((prev) => {
            prev[name] = value;
            return prev;
        });
    };
    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
    const doLoginToBack = () => {
        const cors = { mode: 'cors' };
        const params = {
            email: dataForm.emailField,
            password: dataForm.passwordField
        };
        axios.post(URL_API_SINGIN, params, cors)
            .then( response => {
                console.log(response);
                if (response.data.msg === 'SUCCESSLOGIN')
                    {
                        localStorage.setItem('siscos30', response.data.token);
                        console.log('siscos30: ', response.data.token);
                        navigate('/sites');
                        window.location.reload();
                    }
                else if (response.data.msg === 'NOUSER'){
                    notify('No such a user.');
                }
                else if (response.data.msg === 'BADPASSWD'){
                    notify('Incorrect password');
                }
            })
            .catch(error => {
                console.log(error);
                navigate('/');
                // window.location.reload();
            });
    };
    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
    const handleKeyPress = e => {
        if (e.code === "Enter" || e.code === "NumpadEnter") {
        e.preventDefault();
        doLoginToBack();
      }
    };
    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
    const notify = (thigstonotify) => {
        toast.error(thigstonotify);
    };
    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
    return (
      <Fragment>
        <Toaster position = "top-center" reverseOrder = {false} />
        <div className = "login-page bg-light">
            <div className = "container">
                <div className = "row">
                    <div className = "col-lg-10 offset-lg-1">
                        <div className = "bg-white shadow rounded">
                            <div className = "row">
                                <div className = "col-md-7 pe-0">
                                    <div className = "form-left h-100 py-5 px-5">
                                        <form className = "row g-4">
                                                <div className = "col-12">
                                                    <label>Username<span className = "text-danger">*</span></label>
                                                    <div className = "input-group">
                                                        <div className = "input-group-text"><i className = "fa fa-user"></i></div>
                                                        <input
                                                            type="text"
                                                            className = "form-control"
                                                            name = "emailField"
                                                            onChange = { (event) => handleFields(event) }
                                                        />
                                                    </div>
                                                </div>

                                                <div className = "col-12">
                                                    <label>Password<span className = "text-danger">*</span></label>
                                                    <div className = "input-group">
                                                        <div className = "input-group-text"><i className = "fa fa-lock"></i></div>
                                                        <input
                                                            type="password"
                                                            name = "passwordField"
                                                            className = "form-control"
                                                            onChange = { (event) => handleFields(event) }
                                                            onKeyPress = {handleKeyPress}
                                                        />
                                                    </div>
                                                </div>

                                            {/* <div className = "col-sm-4">
                                                    <div className = "form-check">
                                                        <input className = "form-check-input" type="checkbox" id="inlineFormCheck" />
                                                        <label className = "form-check-label" htmlFor = "inlineFormCheck">Remember me</label>
                                                    </div>
                                                </div>

                                                <div className = "col-sm-7">
                                                    <NavLink className = "float-start text-primary" to = "/resetpass">Forgot Password ?</NavLink>
                                                </div>

                                                <div className = "col-sm-5">
                                                    <NavLink className = "float-end text-primary" to = "/signup">Sign up</NavLink>
                                                </div>*/}

                                                <div className = "col-12">
                                                    <input
                                                            type="button"
                                                            className = "btn btn-primary px-4 float-end mt-1"
                                                            value = 'Login'
                                                            onClick = { doLoginToBack }
                                                    />
                                                </div>
                                        </form>
                                    </div>
                                </div>
                                <div className = "col-md-5 ps-0 d-none d-md-block">
                                    <div className = "form-right h-100 bg-primary text-white text-center pt-5">
                                        <img src = {LogoOgur} className = "my-logo" alt = "sary logo ogur"></img>
                                        <h2 className = "fs-1">We are always ON !</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </Fragment>
      );
  }
 // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
 export default Login;
