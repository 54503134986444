import jwtDecoder from 'jwt-decode';

const decodeTheCurrUserToken = () => {
	const decodedJwt = jwtDecoder(localStorage.getItem('siscos30'));
	// id: decodedJwt.id,
	// llogin: decodedJwt.llogin,
	// rrole: decodedJwt.rrole
	return decodedJwt;
};

export {decodeTheCurrUserToken};