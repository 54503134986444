import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';

import {backUpdateSite, backUpdateAtions} from '../sendtobackend';
import {decodeTheCurrUserToken} from '../getcurrentuser';

import GraphTemp from './GraphTemp';
import GraphHumi from './GraphHumi';
import GraphVolt from './GraphVolt';
import GraphCur from './GraphCur';
import GraphFuel from './GraphFuel';
import GraphTotal from './GraphTotal';

import ImgLoading from '../assets/loading.gif';
import '../index.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';

import urlSite from './ConfigUrl';

import axios from 'axios';
// const URL_ONE_SITE = "https://siscos2.ogur.net:5001/api/getonesite";
const URL_ONE_SITE = urlSite.URL_ONE_SITE;
const URL_API_GRAPHES = urlSite.URL_API_GRAPHES;

const ShowSite = () => {
  const paramsfromdashboard = useParams();

  const [stateModal, setStateModal] = useState(false);
  const [stateOneSite, setStateOneSite] = useState({
                                                SITE_DATA:
                                                    {
                                                        ID: '1',
                                                        NAME: '',
                                                        IP: '',
                                                        CURR_TEMP: '',
                                                        TEMP_TRES: '',
                                                        TEMP_ALARM_STATUS_SOUND: '',
                                                        CURR_HUMI: '',
                                                        HUMI_TRES: '',

                                                        CURR_FUEL: '',
                                                        FUEL_TRES: '',
                                                        
                                                        CURR_VOLT: '',
                                                        VOLT_TRES: '',

                                                        CURR_TOTAL: '',
                                                        TOTAL_TRES: '',
                                                        
                                                        CURR_CUR: '',
                                                        CUR_TRES: '',
                                                        
                                                        GATEWAY: '',
                                                        STATUS: '',
                                                        MONITORED: '',
                                                        ALERT_SMS: '',
                                                        ALERT_EMAIL: '',
                                                        GPS_LAT: '',
                                                        GPS_LON: ''
                                                    },
                                                WIRED_DATA: [
                                                    {
                                                        num: '0',
                                                        namesys: '',
                                                        nameinputname: '',
                                                        nameuser: '',
                                                        currstatus: '',
                                                        license: ''
                                                    },
                                                    {
                                                        num: '1',
                                                        namesys: '',
                                                        nameinputname: '',
                                                        nameuser: '',
                                                        currstatus: '',
                                                        license: ''
                                                    },
                                                    {
                                                        num: '2',
                                                        namesys: '',
                                                        nameinputname: '',
                                                        nameuser: '',
                                                        currstatus: '',
                                                        license: ''
                                                    },
                                                    {
                                                        num: '3',
                                                        namesys: '',
                                                        nameinputname: '',
                                                        nameuser: '',
                                                        currstatus: '',
                                                        license: ''
                                                    },
                                                    {
                                                        num: '4',
                                                        namesys: '',
                                                        nameinputname: '',
                                                        nameuser: '',
                                                        currstatus: '',
                                                        license: ''
                                                    }
                                                ],
                                                WIRELESS_DATA: [
                                                    {
                                                        num: '0',
                                                        namesys: '',
                                                        nameinputname: '',
                                                        nameinputfm: '',
                                                        nameuser: '',
                                                        currstatus: '',
                                                        license: '',
                                                        FM_CODE: ''
                                                    },
                                                    {
                                                        num: '1',
                                                        namesys: '',
                                                        nameinputname: '',
                                                        nameinputfm: '',
                                                        nameuser: '',
                                                        currstatus: '',
                                                        license: '',
                                                        FM_CODE: ''
                                                    },
                                                    {
                                                        num: '2',
                                                        namesys: '',
                                                        nameinputname: '',
                                                        nameinputfm: '',
                                                        nameuser: '',
                                                        currstatus: '',
                                                        license: '',
                                                        FM_CODE: ''
                                                    },
                                                    {
                                                        num: '3',
                                                        namesys: '',
                                                        nameinputname: '',
                                                        nameinputfm: '',
                                                        nameuser: '',
                                                        currstatus: '',
                                                        license: '',
                                                        FM_CODE: ''
                                                    },
                                                    {
                                                        num: '4',
                                                        namesys: '',
                                                        nameinputname: '',
                                                        nameinputfm: '',
                                                        nameuser: '',
                                                        currstatus: '',
                                                        license: '',
                                                        FM_CODE: ''
                                                    }
                                                ],
                                                CAMERA_DATA:
                                                    {
                                                        STATUS: '',
                                                        NAME: '',
                                                        IP: '',
                                                        URL: ''
                                                    },
                                                MQTT_DATA:
                                                    {
                                                        STATUS: '',
                                                        SERVER: '',
                                                        PORT: '',
                                                        TOPIC: '',
                                                        FM_ALARM_CODE_ON: '',
                                                        FM_ALARM_CODE_OFF: ''
                                                    }
                                                });

  const [startDate, setStartDate] = useState(new Date());

  const [stateDataGrapheLabels, setStateDataGrapheLabels] = useState([]);

  const [stateDataGrapheTemp, setStateDataGrapheTemp] = useState([]);
  const [stateDataGrapheHumi, setStateDataGrapheHumi] = useState([]);
  const [stateDataGrapheVolt, setStateDataGrapheVolt] = useState([]);
  const [stateDataGrapheCur, setStateDataGrapheCur] = useState([]);
  const [stateDataGrapheFuel, setStateDataGrapheFuel] = useState([]);
  const [stateDataGrapheTotal, setStateDataGrapheTotal] = useState([]);

  const [stateWithPicker, setStateWithPicker] = useState(true);


  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
	const sendReqToBackForGrapheData = async (startDate) => {
        setStartDate(startDate);
        let yearForDate = startDate.toLocaleString("default", { year: "numeric" });
        let monthForDate = startDate.toLocaleString("default", { month: "2-digit" });
        let dayForDate = startDate.toLocaleString("default", { day: "2-digit" });
        const dateToSend = yearForDate + monthForDate + dayForDate;
    
        const cors = {mode:'cors'};
        const params = { D_DATE: dateToSend};
        await axios.post(URL_API_GRAPHES, params, cors)
            .then(response => {
              
                if (response.data.msg === 'SUCCESS_GRAPH') {

                    // time for all data
                    let valuesLabels = response.data.result.map((item) => item.D_TIME);
                    setStateDataGrapheLabels(valuesLabels);
                    console.log('TTIME: ', valuesLabels);

                    // temp
                    let valuesTemp = response.data.result.map((item) => item.D_TEMP);
                    setStateDataGrapheTemp(valuesTemp);
                    console.log('TDATA: ', valuesTemp);

                    // humi
                    let valuesHumi = response.data.result.map((item) => item.D_HUMI);
                    setStateDataGrapheHumi(valuesHumi);

                    // volt
                    let valuesVolt = response.data.result.map((item) => item.D_VOLTAGE);
                    setStateDataGrapheVolt(valuesVolt);

                    // current
                    let valuesCur = response.data.result.map((item) => item.D_CURRENT);
                    setStateDataGrapheCur(valuesCur);
                    
                    // fuel
                    let valuesFuel = response.data.result.map((item) => item.D_FUEL);
                    setStateDataGrapheFuel(valuesFuel);

                    // total consumption
                    let valuesTotal = response.data.result.map((item) => item.D_TOTAL);
                    setStateDataGrapheTotal(valuesTotal);

                    notify('Got data from graph API.', 'goood');
                }
                else if (response.data.msg === 'NO_DATA') {
                    notify('There is no data for this date.', 'baaad');
                }
            })
            .catch(error => {
              notify('There is no data for this date.', 'baaad');
            });
        }
    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
    useEffect(() => {
        toast.promise(sendReqToBackForGrapheData(startDate), {
                                loading: "Compiling graphs data from database...",
                                error: "Oh oh!, there was an error while fetching the data",
                                success: "Successfully got graph data."
                                });
                    }, [startDate]);
    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
    const getSite = async () => {
        await axios.get(URL_ONE_SITE, { params: { getWhere: paramsfromdashboard.siteid } }).then( req_response => {
            const data_site = req_response.data.data[0];
            setStateOneSite(data_site);
        })}
    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
    useEffect(() => {
            toast.promise(getSite(), {
                                    loading: "Compiling site data from database...",
                                    error: "Oh oh!, there was an error while fetching the data",
                                    success: "Successfully got sensors data."
                                    });
                        }, []);
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  const handleCheckBoxes = (event, wiredOrNot, currIndex, currSensorNum, currSensorName) => {
      const { checked } = event.target;
      
      if (checked) {
        let arraytopush = [...stateOneSite[wiredOrNot]];
        arraytopush[currIndex].currstatus = 'MONITORED';
        setStateOneSite({ ...stateOneSite, [wiredOrNot]: arraytopush,});
        
        const newStateTemp = { ...stateOneSite, [wiredOrNot]: arraytopush,};
        backUpdateSite(newStateTemp, newStateTemp.SITE_DATA.ID);
        
        const {llogin} = decodeTheCurrUserToken();
        const myDate = new Date();
        const objtoinsert = {
                                DATE_TIME: myDate.toUTCString(),
                                REASON: `The sensor ${currSensorName} is now monitored.`,
                                SITE: newStateTemp.SITE_DATA.NAME,
                                ACTION: 'Monitor',
                                CONNECTED_USER: llogin
                            };
        backUpdateAtions(objtoinsert);
        console.log('action_insert: ', objtoinsert);

        notify(`The sensor ${currSensorName} is now MONITORED.`, 'goood');
      }
      else if (!checked) {
        let arraytopush = [...stateOneSite[wiredOrNot]];
        arraytopush[currIndex].currstatus = 'IGNORED';
        setStateOneSite({ ...stateOneSite, [wiredOrNot]: arraytopush,});
        
        const newStateTemp = { ...stateOneSite, [wiredOrNot]: arraytopush,};
        backUpdateSite(newStateTemp, newStateTemp.SITE_DATA.ID);

        const {llogin} = decodeTheCurrUserToken();
        const myDate = new Date();
        const objtoinsert = {
                                DATE_TIME: myDate.toUTCString(),
                                REASON: `The sensor ${currSensorName} is now unmonitored.`,
                                SITE: newStateTemp.SITE_DATA.NAME,
                                ACTION: 'Unmonitor',
                                CONNECTED_USER: llogin
                            };
        backUpdateAtions(objtoinsert);
        console.log('action_insert: ', objtoinsert);
        
        notify(`The sensor ${currSensorName} is now IGNORED.`, 'baaad');
      }
    };
    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
    const notify = (thigstonotify, goodorbad) => {
        if (goodorbad === 'goood') {
          toast.success(thigstonotify);
        }
        else if (goodorbad === 'baaad') {
          toast.error(thigstonotify);
        }
    };
    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
    const handleModalShow = () => {
        setStateModal(true);
    };
    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
    const handleModalClose = () => {
        setStateModal(false);
    };
    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
    const handleThemeBadge = (status) => {
        if (status === 'MONITORED') {
            return 'bg-info text-light badge float-start align-middle fw-normal fs-6'
        }
        else if (status === 'IGNORED') {
            return 'bg-secondary text-light badge float-start align-middle fw-normal fs-6'
        }
        else if (status === 'ALARM') {
            return 'bg-danger text-light badge float-start align-middle fw-normal fs-6'
        }
    };
    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
    const handleTextBadge = (status) => {
        if (status === 'MONITORED') {
            return 'Monitored'
        }
        else if (status === 'IGNORED') {
            return 'Ignored'
        }
        else if (status === 'ALARM') {
            return 'Alarm'
        }
    };
    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
    const handleThemeCard = (status) => {
        if (status === 'MONITORED') {
            return 'card mt-1 ms-1 border-info'
        }
        else if (status === 'IGNORED') {
            return 'card mt-1 ms-1 border-secondary'
        }
        else if (status === 'ALARM') {
            return 'card mt-1 ms-1 border-info my-border-alarm border-danger'
        }
    };
    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
    return (
        <Fragment>

        <Toaster position = "top-center" reverseOrder = {false} />

        <Modal show = {stateModal} onHide = {() => handleModalClose()} size = 'xl'>
            
            <Modal.Header closeButton>
              <Modal.Title>Images from {stateOneSite.CAMERA_DATA.NAME} camera</Modal.Title>
            </Modal.Header>
            
            <Modal.Body className = "text-center">
              <img            
                className = "img-fluid"
                src = {ImgLoading}
                alt = 'test gif'
              />
            </Modal.Body>

        </Modal>

        <div className = "text-center mb-4">
                <div>
                    <small className = "text-muted">
                        <span
                            className = "badge my-backgroud-section fs-5 m-1 fw-normal">
                                {stateOneSite.SITE_DATA.NAME}
                        </span>
                    </small>

                    <small className = "text-muted">
                        <span
                            className = {`${stateOneSite.SITE_DATA.CURR_VOLT < stateOneSite.SITE_DATA.VOLT_TRES ? 'my-backgroud-section-danger' : 'my-backgroud-section-success'} shadow-lg rounded badge fw-normal fs-6 m-1`}>
                                Voltage: {stateOneSite.SITE_DATA.CURR_VOLT} V
                        </span>
                    </small>

                    <small className = "text-muted">
                        <span
                            className = {`${stateOneSite.SITE_DATA.CURR_CUR < stateOneSite.SITE_DATA.CUR_TRES ? 'my-backgroud-section-danger' : 'my-backgroud-section-success'} shadow-lg rounded badge fw-normal fs-6 m-1`}>
                                Current: {stateOneSite.SITE_DATA.CURR_CUR} A
                        </span>
                    </small>

                    <small className = "text-muted">
                        <span
                            className = "badge shadow-lg rounded bg-light text-dark fs-6 m-1 fw-normal">
                                Consumption: {stateOneSite.SITE_DATA.CURR_TOTAL} W
                        </span>
                    </small>

                    <small className = "text-muted">
                        <span
                            className = {`${stateOneSite.SITE_DATA.MONITORED === 'ON' ? 'my-backgroud-section-success' : 'my-backgroud-section-danger'} shadow-lg rounded badge fw-normal fs-6 m-1`}>
                                { stateOneSite.SITE_DATA.MONITORED === 'ON' ? "Monit: ON" : "Monit: OFF" }
                        </span>
                    </small>
                    <small className = "text-muted">
                        <span
                            className = {`${stateOneSite.SITE_DATA.STATUS === 'ONLINE' ? 'my-backgroud-section-success' : 'my-backgroud-section-danger'} shadow-lg rounded badge fw-normal fs-6 m-1`}>
                                {stateOneSite.SITE_DATA.STATUS === 'ONLINE' ? 'PING: OK' : 'PING: KO'}
                        </span>
                    </small>
                    <small className = "text-muted">
                        <span
                            className = {`${stateOneSite.SITE_DATA.CURR_TEMP > stateOneSite.SITE_DATA.TEMP_TRES ? 'my-backgroud-section-danger' : 'my-backgroud-section-success'} shadow-lg rounded badge fw-normal fs-6 m-1`}>
                                Temperature: {stateOneSite.SITE_DATA.CURR_TEMP} °C
                        </span>
                    </small>
                    <small className = "text-muted">
                        <span
                            className = {`${stateOneSite.SITE_DATA.CURR_HUMI > stateOneSite.SITE_DATA.HUMI_TRES ? 'my-backgroud-section-danger' : 'my-backgroud-section-success'} shadow-lg rounded badge fw-normal fs-6 m-1`}>
                                Humidity: {stateOneSite.SITE_DATA.CURR_HUMI} %
                        </span>
                    </small>
                    <small className = "text-muted">
                        <span
                            className = {`${stateOneSite.SITE_DATA.CURR_FUEL < stateOneSite.SITE_DATA.FUEL_TRES ? 'my-backgroud-section-danger' : 'my-backgroud-section-success'} shadow-lg rounded badge fw-normal fs-6 m-1`}>
                                Fuel level: {stateOneSite.SITE_DATA.CURR_FUEL} %
                        </span>
                    </small>
                        
                    <small className = "text-muted">
                        <span
                            className = {`${stateOneSite.SITE_DATA.ALERT_SMS === 'ON' ? 'my-backgroud-section-success' : 'my-backgroud-section-danger'} shadow-lg rounded badge fw-normal fs-6 m-1`}>
                                SMS: {stateOneSite.SITE_DATA.ALERT_SMS}
                        </span>
                    </small>
                    <small className = "text-muted">
                        <span
                            className = {`${stateOneSite.SITE_DATA.ALERT_EMAIL === 'ON' ? 'my-backgroud-section-success' : 'my-backgroud-section-danger'} shadow-lg rounded badge fw-normal fs-6 m-1`}>
                                Email: {stateOneSite.SITE_DATA.ALERT_EMAIL}
                        </span>
                    </small>
                </div>
        </div>

        <div className = "row">
            
            <div className = "col-md-2">
              <img
                className = "img-fluid mt-1 ms-1 mb-1"
                onClick = {handleModalShow}
                src = {ImgLoading}
                alt = 'test gif'
              />
              
              <span className = "ms-1 bg-light text-dark badge float-start fw-normal fs-6">
                cam: {stateOneSite.CAMERA_DATA.NAME}
              </span>

            </div>
          
            <div className = "col-md-10">
              <div className = "list-group-item mt-1 shadow-lg rounded mb-3">
              <div className = "input-group-text fs-6 text-light my-backgroud-section border-light" style = {{width: 165, height: 32}}>GPIO sensors</div>
              

                  {stateOneSite.WIRED_DATA.map((currWiredSensor, currIndex) => (
                            <div
                            key = {currWiredSensor.num}
                            className = {handleThemeCard(currWiredSensor.currstatus)}
                            style = {currWiredSensor.license === "ON" ? {width: 195, display: 'inline-block'} : {display: 'none'}}>
                            
                            {currWiredSensor.license === "ON" ? <div className = "m-2">
                                <h6 className = {`card-title ${currWiredSensor.currstatus === 'ALARM' ? 'text-danger fw-bold' : null}`}>{currWiredSensor.nameuser}</h6>
                            </div> : null}
                            
                            {currWiredSensor.license === "ON" ? <div className = "card-footer bg-default align-items-center">
                                
                                <span className = {handleThemeBadge(currWiredSensor.currstatus)}>
                                {handleTextBadge(currWiredSensor.currstatus)}
                                </span>

                                <div className="form-check checkbox-info form-switch ms-1">
                                <input
                                    onChange = {(event) => handleCheckBoxes(event, 'WIRED_DATA', currIndex, currWiredSensor.num, currWiredSensor.nameuser)}
                                    style = {{width: 40, height: 20}}
                                    name = "WIRED_CHECKBOX"
                                    className = "form-check-input float-end"
                                    type = "checkbox"
                                    checked = {currWiredSensor.currstatus === 'MONITORED' ? true : false}
                                    // sensors button depends on the status of the site
                                    disabled = {stateOneSite.SITE_DATA.MONITORED === 'OFF' ? true : false}
                                />
                                </div>
                            </div> : null}
                            </div>

                  ))}
              </div>

              <div className = "list-group-item mt-4 shadow-lg rounded mb-4">
              <div className = "input-group-text fs-6 text-light my-backgroud-section border-light" style={{width: 190, height: 32}}>Wireless sensors</div>

                  {stateOneSite.WIRELESS_DATA.map((currWirelessSensor, currIndex) => (
                    <div
                      key = {currWirelessSensor.num}
                      className = {handleThemeCard(currWirelessSensor.currstatus)}
                      style = {currWirelessSensor.license === "ON" ? {width: 195, display: 'inline-block'} : {display: 'none'}}>
                      
                      {currWirelessSensor.license === "ON" ? <div className = "m-2">
                        <h6 className = {`card-title ${currWirelessSensor.currstatus === 'ALARM' ? 'text-danger fw-bold' : null}`}>{currWirelessSensor.nameuser}</h6>
                      </div> : null}
                      
                      {currWirelessSensor.license === "ON" ? <div className = "card-footer bg-default align-items-center">
                        
                        <span className = {handleThemeBadge(currWirelessSensor.currstatus)}>
                          {handleTextBadge(currWirelessSensor.currstatus)}
                        </span>

                        <div className="form-check checkbox-info form-switch ms-1">
                          <input
                            onChange = {(event) => handleCheckBoxes(event, 'WIRELESS_DATA', currIndex, currWirelessSensor.num, currWirelessSensor.nameuser)}
                            style = {{width: 40, height: 20}}
                            name = "WIRELESS_CHECKBOX"
                            className = "form-check-input float-end"
                            type = "checkbox"
                            checked = {currWirelessSensor.currstatus === 'MONITORED' ? true : false}
                            disabled = {stateOneSite.SITE_DATA.MONITORED === 'OFF' ? true : false}
                          />
                        </div>
                      </div> : null}
                    </div>

                  ))}
            </div>

            <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <GraphTemp
                      p_datatemp = {stateDataGrapheTemp}
                      p_withpicker = {stateWithPicker}
                      p_tempLabels = {stateDataGrapheLabels}
                    />
                  </div>
                  <div className="col-md-6">
                    <GraphHumi
                      p_datahumi = {stateDataGrapheHumi}
                      p_withpicker = {stateWithPicker}
                      p_tempLabels = {stateDataGrapheLabels}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <GraphVolt
                      p_datavolt = {stateDataGrapheVolt}
                      p_withpicker = {stateWithPicker}
                      p_tempLabels = {stateDataGrapheLabels}
                    />
                  </div>
                  <div className="col-md-6">
                    <GraphCur
                      p_datacur = {stateDataGrapheCur}
                      p_withpicker = {stateWithPicker}
                      p_tempLabels = {stateDataGrapheLabels}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <GraphFuel
                      p_datafuel = {stateDataGrapheFuel}
                      p_withpicker = {stateWithPicker}
                      p_tempLabels = {stateDataGrapheLabels}
                    />
                  </div>
                  <div className="col-md-6">
                    <GraphTotal
                      p_datatotal = {stateDataGrapheTotal}
                      p_withpicker = {stateWithPicker}
                      p_tempLabels = {stateDataGrapheLabels}
                    />
                  </div>
                </div>
              </div>

        </div>

        
        </div>

        
      
        </Fragment>
            );
            }
 // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
 export default ShowSite;