import axios from 'axios';

// db.yourCollectionName.update({filter},{$set:{yourFieldName:yourVariableName}});

const URL_API_UPDATE_SITES = 'https://siscos.ogur.net:5001/api/updatecurrentsite';
const URL_API_UPDATE_ACTIONS = 'https://siscos.ogur.net:5001/api/updateactions';
const URL_API_UPDATE_USER = 'https://siscos.ogur.net:5001/api/updatecurrentuser';
const URL_API_DELETE_USER = 'https://siscos.ogur.net:5001/api/deleteuser';
const URL_API_UPDATE_INSERT_SITE = 'https://siscos.ogur.net:5001/api/updateinsertsite';

// =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
const backUpdateSite = async (up_value, up_where) => {
    const params = {up_where, up_value}; // same key value, so ES6 use on term only
    const corsObj = {mode: "cors"};
    await axios.post(URL_API_UPDATE_SITES, params, corsObj)
        .then( response => {
            if (response.status === 200)
            {
                console.log('siscos30_afterdb_update: ', response.data);
            }
            else if (response.status === 401)
            {
                console.log('ERROR: ', 'Can not update database.');
            }
        })
        .catch(error => {console.log(error);});

};
// =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
const backUpdateAtions = async (objtoinsert) => {
    const params = { objtoinsert }; // same key value, so ES6 use one term only
    const corsObj = {mode: "cors"};
    await axios.post(URL_API_UPDATE_ACTIONS, params, corsObj)
        .then( response => {
            if (response.status === 200)
            {
                console.log('siscos30_afterdb_update: ', response.data);
            }
            else if (response.status === 401)
            {
                console.log('ERROR: ', 'Can not update database.');
            }
        })
        .catch(error => {console.log(error);});

};
// =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
const backUpdateInsertSite = async (objtoinsert) => {
    const params = { objtoinsert }; // same key value, so ES6 use one term only
    const corsObj = {mode: "cors"};
    await axios.post(URL_API_UPDATE_INSERT_SITE, params, corsObj)
        .then( response => {
            if (response.status === 200)
            {
                console.log('siscos30_afterdb_insert: ', response.data);
            }
            else if (response.status === 401)
            {
                console.log('ERROR: ', 'Can not insert into database.');
            }
        })
        .catch(error => {console.log(error);});

};
// =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
const backUpdateUser = async (usertoinsert) => {
    const params = {usertoinsert}; // same key value, so ES6 use one term only

    const corsObj = {mode: "cors"};
    await axios.post(URL_API_UPDATE_USER, params, corsObj)
        .then( response => {
            if (response.status === 200)
            {
                console.log('siscos30_afterdb_update: ', response.data);
            }
            else if (response.status === 401)
            {
                console.log('ERROR: ', 'Can not update database.');
            }
        })
        .catch(error => {console.log(error);});

};
// =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
const backDeleteUser = async (objtodelete) => {
    const params = {objtodelete}; // same key value, so ES6 use one term only

    console.log(params);
    const corsObj = {mode: "cors"};
    await axios.post(URL_API_DELETE_USER, params, corsObj)
        .then( response => {
            if (response.status === 200)
            {
                console.log('siscos30_afterdb_update: ', response.data);
            }
            else if (response.status === 401)
            {
                console.log('ERROR: ', 'Can not update database.');
            }
        })
        .catch(error => {console.log(error);});

};
// =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
export {backUpdateSite, backUpdateAtions, backUpdateUser, backDeleteUser, backUpdateInsertSite};
