import React, { useState, useEffect, Fragment } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { NavLink } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import {decodeTheCurrUserToken} from '../getcurrentuser';
import {backUpdateSite, backUpdateAtions} from '../sendtobackend';

import ImgLoading from '../assets/loading.gif';
import '../index.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';

import axios from 'axios';

import urlSite from './ConfigUrl';

// const URL_ALL_SITES = "https://siscos2.ogur.net:5001/api/getallsites";
const URL_ALL_SITES = urlSite.URL_ALL_SITES;

const Sites = (props) => {

    const navigate = useNavigate();
    
    if ( !localStorage.getItem('siscos30') ) {
        setTimeout(function() {
          navigate('/');
        }, 100);
    }

	const [stateAllSites, setStateAllSite] = useState([]);
    const [stateModal, setStateModal] = useState(false);
	// =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
	const getSites =  async () => {
	   await axios.get(URL_ALL_SITES).then( req_response => {
	   const data_sites = req_response.data.data;
	        setStateAllSite(data_sites);
	      })
	  }
    // =-=-=-=-=-=-=-=-=-=-=-=-=-=--=-=-=-=-=-=-=-=-=-=
    useEffect(() => {
        toast.promise(getSites(), {
                                loading: "Compiling data from database...",
                                error: "Oh oh!, there was an error while fetching the data",
                                success: "Successfully got data."
                                  });
                    }, []);
	// =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  	const handleCheckBoxes = (event, currIndex, currSite) => {
  		const { checked } = event.target;
  		if (checked) {
            const siteToSenToBackEnd = {...currSite, SITE_DATA: {...currSite.SITE_DATA, MONITORED: 'ON'},};
            const newStateArray = [...stateAllSites];
            newStateArray[currIndex] = siteToSenToBackEnd;
            
            // UI update
            setStateAllSite(newStateArray);
            
            // optimistic backend update
            backUpdateSite(siteToSenToBackEnd, currSite.SITE_DATA.ID);

            const {llogin} = decodeTheCurrUserToken();
            const myDate = new Date();
            const objtoinsert = {
                                    DATE_TIME: myDate.toUTCString(),
                                    REASON: `The site ${currSite.SITE_DATA.NAME} is now monitored.`,
                                    SITE: currSite.SITE_DATA.NAME,
                                    ACTION: 'Monitor',
                                    CONNECTED_USER: llogin
                                };
            backUpdateAtions(objtoinsert);

  			notify(`The site ${currSite.SITE_DATA.NAME} is now monitored.`, 'goood');
  		}
  		else if (!checked) {
            const siteToSenToBackEnd = {...currSite, SITE_DATA: {...currSite.SITE_DATA, MONITORED: 'OFF'},};
            const newStateArray = [...stateAllSites];
            newStateArray[currIndex] = siteToSenToBackEnd;
            
            // UI update
            setStateAllSite(newStateArray);
            
            // optimistic backend update
            backUpdateSite(siteToSenToBackEnd, currSite.SITE_DATA.ID);

            const {llogin} = decodeTheCurrUserToken();
            const myDate = new Date();
            const objtoinsert = {
                                    DATE_TIME: myDate.toUTCString(),
                                    REASON: `The site ${currSite.SITE_DATA.NAME} is now ignored.`,
                                    SITE: currSite.SITE_DATA.NAME,
                                    ACTION: 'Ignore',
                                    CONNECTED_USER: llogin
                                };
            backUpdateAtions(objtoinsert);

  			notify(`The site ${currSite.SITE_DATA.NAME} is now ignored.`, 'baaad');
  		}
  	};
  	// =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  	const notify = (thigstonotify, goodorbad) => {
  	     if (goodorbad === 'goood') {
  	     	toast.success(thigstonotify);
  	     }
  	     else if (goodorbad === 'baaad') {
  	     	toast.error(thigstonotify);
  	     }
  	};
    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
    const handleModalShow = () => {
        setStateModal(true);
    };
    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
    const handleModalClose = () => {
        setStateModal(false);
    };
    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  	return (
  		<Fragment>
			<div>

                <Toaster position = "top-center" reverseOrder = {false} />
			     
                
                <div className="row">
                        {/*AMBONY=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=*/}
				        <div className = "row mb-2">
                          <span className = "ms-1 bg-light text-dark badge fw-normal fs-5">
                                <div>

                                    <small className = "float-start text-muted">
                                        Sites: 
                                        <span
                                            className = "badge rounded-pill my-backgroud-section fs-6 m-1 fw-normal">
                                                {stateAllSites.length}
                                        </span>
                                    </small>
                                    <small className = "float-start text-muted">
                                        <span
                                            className = "badge rounded-pill text-dark fs-6 m-1 fw-normal">
                                                License ID: f76543deacbef5431908
                                        </span>
                                    </small>
                                    

                                    <small className = "float-end text-muted">
                                        <input type = "search" className = "form-control" placeholder = "Filter sites..."/>
                                    </small>
                                    
                                </div>
                          </span>
                        </div>

				    	{/*AMBANY=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=*/}
				    	<div className = "list-group-item text-center rounded">
				        
				        	{stateAllSites.map((currSite, currIndex) => (
                                <span
                                        key = {currSite.SITE_DATA.ID}
                                        className = {`${currSite.SITE_DATA.MONITORED === 'ON' ? 'card border-info m-1' : 'card border-secondary m-1'}`}
                                        style={{width: 460, display: 'inline-block'}}>
                                    
                                    {/*modal camera*/}
                                    <Modal show = {stateModal} onHide = {() => handleModalClose()} size = 'xl'>
  
                                        <Modal.Header closeButton>
                                          <Modal.Title>Images from the site's camera</Modal.Title>
                                        </Modal.Header>
                                        
                                        <Modal.Body className = "text-center">
                                            <img            
                                                className = "img-fluid"
                                                src = {ImgLoading}
                                                alt = 'test gif'
                                            />
                                        </Modal.Body>

                                    </Modal>

                                      {/*-----------*/}
                                      <div className = "align-items-center card-header">

                                        <div className = "row align-items-center">
                                            <div className = "col-8">
                                                
                                                <span className = {`${currSite.SITE_DATA.MONITORED === 'ON' ? 'btn-grad text-light rounded-pill float-start fw-normal fs-5' : 'bg-secondary text-light rounded-pill float-start fw-normal fs-5'}`}>
                                                    <NavLink className = "text-light nav-link ms-3 me-3" aria-current = "page" to = {`/showsite/${currSite.SITE_DATA.ID}`}>
                                                        {currSite.SITE_DATA.NAME}
                                                    </NavLink>
                                                </span>

                                            </div>
                                            
                                            <div className = "col-4 form-check checkbox-info form-switch">
                                              <input
                                                onChange = {(event) => handleCheckBoxes(event, currIndex, currSite)}
                                                style = {{width: 60, height: 30}}
                                                name = "MONITORED"
                                                className = "form-check-input float-end"
                                                type = "checkbox"
                                                defaultChecked = {currSite.SITE_DATA.MONITORED === 'ON' ? true : false}
                                              />
                                            </div>

                                        </div>
                                      
                                      </div>
                                      {/*-----------*/}
                                      <div onClick = {handleModalShow} className = "card-body text-center">
                                        <img
                                          className = "img-fluid"
                                          src = {ImgLoading}
                                          alt = 'test gif'
                                          />
                                      </div>
                                      {/*-----------*/}
                                      <div className = "container card-footer bg-default text-center">
                                            <div>
                                                <small className = "text-muted">
                                                    <span
                                                        className = {`${currSite.SITE_DATA.CURR_VOLT < currSite.SITE_DATA.VOLT_TRES ? 'my-backgroud-section-danger' : 'my-backgroud-section-success'} shadow-lg rounded badge fw-normal fs-6 m-1`}>
                                                            {currSite.SITE_DATA.CURR_VOLT} V
                                                    </span>
                                                </small>

                                                <small className = "text-muted">
                                                    <span
                                                        className = {`${currSite.SITE_DATA.CURR_CUR < currSite.SITE_DATA.CUR_TRES ? 'my-backgroud-section-danger' : 'my-backgroud-section-success'} shadow-lg rounded badge fw-normal fs-6 m-1`}>
                                                            {currSite.SITE_DATA.CURR_CUR} A
                                                    </span>
                                                </small>

                                                <small className = "text-muted">
                                                    <span
                                                        className = "badge shadow-lg rounded bg-light text-dark fs-6 m-1 fw-normal">
                                                            {currSite.SITE_DATA.CURR_TOTAL} W
                                                    </span>
                                                </small>

                                                <small className = "text-muted">
                                                    <span
                                                        className = {`${currSite.SITE_DATA.STATUS === 'ONLINE' ? 'my-backgroud-section-success' : 'my-backgroud-section-danger'} shadow-lg rounded badge fw-normal fs-6 m-1`}>
                                                            {currSite.SITE_DATA.STATUS === 'ONLINE' ? 'VPN: OK' : 'VPN: KO'}
                                                    </span>
                                                </small>
                                                <small className = "text-muted">
                                                    <span
                                                        className = {`${currSite.SITE_DATA.CURR_TEMP > currSite.SITE_DATA.TEMP_TRES ? 'my-backgroud-section-danger' : 'my-backgroud-section-success'} shadow-lg rounded badge fw-normal fs-6 m-1`}>
                                                            {currSite.SITE_DATA.CURR_TEMP} °C
                                                    </span>
                                                </small>
                                                <small className = "text-muted">
                                                    <span
                                                        className = {`${currSite.SITE_DATA.CURR_HUMI > currSite.SITE_DATA.HUMI_TRES ? 'my-backgroud-section-danger' : 'my-backgroud-section-success'} shadow-lg rounded badge fw-normal fs-6 m-1`}>
                                                            {currSite.SITE_DATA.CURR_HUMI} %
                                                    </span>
                                                </small>
                                                <small className = "text-muted">
                                                    <span
                                                        className = {`${currSite.SITE_DATA.CURR_FUEL < currSite.SITE_DATA.FUEL_TRES ? 'my-backgroud-section-danger' : 'my-backgroud-section-success'} shadow-lg rounded badge fw-normal fs-6 m-1`}>
                                                            {currSite.SITE_DATA.CURR_FUEL} %
                                                    </span>
                                                </small>
                                                
                                
                                                <small className = "text-muted">
                                                    <span
                                                        className = {`${currSite.SITE_DATA.ALERT_SMS === 'ON' ? 'my-backgroud-section-success' : 'my-backgroud-section-danger'} shadow-lg rounded badge fw-normal fs-6 m-1`}>
                                                            SMS: {currSite.SITE_DATA.ALERT_SMS}
                                                    </span>
                                                </small>
                                                <small className = "text-muted">
                                                    <span
                                                        className = {`${currSite.SITE_DATA.ALERT_EMAIL === 'ON' ? 'my-backgroud-section-success' : 'my-backgroud-section-danger'} shadow-lg rounded badge fw-normal fs-6 m-1`}>
                                                            Email: {currSite.SITE_DATA.ALERT_EMAIL}
                                                    </span>
                                                </small>
                                                <small className = "text-muted">
                                                    <span
                                                        className = {`${currSite.SITE_DATA.MONITORED === 'ON' ? 'my-backgroud-section-success' : 'my-backgroud-section-danger'} shadow-lg rounded badge fw-normal fs-6 m-1`}>
                                                            { currSite.SITE_DATA.MONITORED === 'ON' ? "Monit: ON" : "Monit: OFF" }
                                                    </span>
                                                </small>

                                                </div>
                                      </div>
                                      {/*-----------*/}
                                    </span>

                              ))}


				        
				        </div>
				        {/*=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=*/}
			    </div>
			</div>
		</Fragment>
	);
}
 // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
 export default Sites;
